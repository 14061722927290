import { addCurrency, divideCurrency, multiplyCurrency } from "../utils/money";
import { constants } from "../constants";

const {
  PAYMENT_METHODS: { CASH, CREDIT_CARD, DNA, NEARPAY, SPLIT_PAYMENT },
} = constants;

export const getTaxCategoryTaxes = (taxCategoryId, locationTaxes = []) => (
  locationTaxes.filter(tax => tax.tax_category_id == taxCategoryId)
);

export const getApplicableTaxCategoryTaxes = (taxCategoryId, applicableOnCard, locationTaxes = []) => {
  locationTaxes ||= [];
  let applicableTaxes = locationTaxes.filter(tax => (
    //cater for tax.applicable_on_card nil via !!
    tax.tax_category_id == taxCategoryId && !!tax.applicable_on_card == applicableOnCard
  ));
  if (applicableTaxes.length > 0) {
    return applicableTaxes;
  }
  //else no tax specifically applicable on card available, so just default to ignoring
  return getTaxCategoryTaxes(taxCategoryId, locationTaxes);
};

export const isOrderGstCardApplicable = (order, isCardPayment = null) => {
  if(isCardPayment != null) return isCardPayment;
  // return Number(location.gst_ccard) > 0 && (
  return (
    isOrderCardPaymentSelected(order) ||
    getOrderCardPaymentRatio(order) > 0
  );
};

export const isOrderCardPaymentSelected = (order) => {
  const paymentMethodOption = order.payment_methods || order.paymentMethod;
  return paymentMethodOption.includes(CREDIT_CARD) ||
    paymentMethodOption.includes(DNA) ||
    paymentMethodOption.includes(NEARPAY) ||
    paymentMethodOption.includes('ccard_stripe');
};

export const getOrderCardPaymentRatio = (order) => (
  order.card_payment_ratio || calculateOrderCardPaymentRatio(order)
)

export const resetOrderCardPaymentRatio = (order) => (
  order.card_payment_ratio = calculateOrderCardPaymentRatio(order)
)

export const calculateOrderCardPaymentRatio = (order) => {
  if (order.payment_methods || order.paymentMethod) {
    if (isOrderCardPaymentSelected(order)) {
      return 1
    }
    else {
      return 0;
    }
  }
  else {
    let cardAmount = 0;
    order.payments.forEach(payment => {
      if (payment.card && !payment.cancelled) {
        cardAmount = addCurrency(cardAmount, payment.amount, payment.currency || order.currency);
      }
    });
    //if card payment is more than total taxable amount, then apply tax on total taxable amount
    const discountedAmount = getOrderDiscountedAmount(order);
    if (cardAmount > discountedAmount) {
      return 1;
    } else {
      return cardAmount / discountedAmount;
    }
  }
}

export const calculateLegacyTax = (
  taxableAmount,
  isCardPayment,
  gstCustom,
  gst,
  gst_ccard,
  currency,
  includedInPrice
) => {
  let applicableTaxAmount;
  if (gstCustom || gstCustom === 0) {
    applicableTaxAmount = gstCustom / 100;
  } else {
    if ((gst_ccard || gst_ccard === 0) && isCardPayment) {
      applicableTaxAmount = gst_ccard / 100;
    } else {
      applicableTaxAmount = gst / 100;
    }
  }
  if (includedInPrice) {
    taxableAmount = divideCurrency(
      taxableAmount,
      1 + applicableTaxAmount,
      currency,
    );

  }
  return multiplyCurrency(taxableAmount, applicableTaxAmount, currency);
};

export const calculateTaxableItemPreTaxAmount = (taxableAmount, taxableItem, order, locationTaxes = [], isCardPayment = null, applicableTaxes = null) => {
  if (order.custom_gst || order.custom_gst === 0) {
    return taxableAmount;
  }  
  let preTaxAmount = taxableAmount;
  if (!taxableItem.tax_category_id) return preTaxAmount;

  const isCardPaymentTaxApplicable = isOrderGstCardApplicable(order, isCardPayment);
  applicableTaxes ||= getApplicableTaxCategoryTaxes(taxableItem.tax_category_id, isCardPaymentTaxApplicable, locationTaxes);
  const includedTaxes = applicableTaxes.filter(tax => tax.included_in_price);
  if (includedTaxes.length > 0) {
    preTaxAmount = divideCurrency(
      preTaxAmount,
      1 +
      includedTaxes.reduce(
        (result, tax) =>
          addCurrency(result, tax.amount, order.currency),
        0,
      ),
      order.currency,
    );
  }
  return preTaxAmount;
};

// expects taxableItem.discounted_amount to be set before call
export const resetTaxableItemPreTaxAmount = (
  taxableItem, 
  order, 
  locationTaxes = [], 
  isCardPayment = null,
  applicableTaxes = null
) => (
  taxableItem.pre_tax_amount = calculateTaxableItemPreTaxAmount(
    taxableItem.discounted_amount, 
    taxableItem, 
    order, 
    locationTaxes, 
    isCardPayment,
    applicableTaxes
  )
);

// expects taxableItem.discounted_amount to be set before call
export const resetTaxableItemTotalTax = (taxableItem, order, locationTaxes, isCardPayment = null) => {
  taxableItem.total_included_tax = taxableItem.total_additional_tax = 0;
  if (order.custom_gst || order.custom_gst === 0) {
    taxableItem.total_additional_tax = multiplyCurrency(
      taxableItem.discounted_amount, 
      order.custom_gst / 100, 
      order.currency
    );
  } else {
    const isCardPaymentTaxApplicable = isOrderGstCardApplicable(order, isCardPayment);
    
    if(isCardPaymentTaxApplicable) {
      let cardPaymentRatio = getOrderCardPaymentRatio(order)
      if(cardPaymentRatio > 1) cardPaymentRatio = 1
      const nonCardPaymentRatio = 1 - cardPaymentRatio
      const taxCategoryTaxes = getTaxCategoryTaxes(taxableItem.tax_category_id, locationTaxes);
      //TODO for mix payments, the applicable tax is tricky and for now
      //card tax is applied for preTax calculation which might not be fully correct (needs to be verified)
      resetTaxableItemPreTaxAmount(taxableItem, order, locationTaxes, isCardPayment);
      let cashTaxApplied = false;
      let cardTaxApplied = false;
      taxCategoryTaxes.forEach(tax => {
        let paymentPreTaxAmount;
        if(tax.applicable_on_card) {
          paymentPreTaxAmount = taxableItem.pre_tax_amount * cardPaymentRatio;
          cardTaxApplied = true;
        } else {
          paymentPreTaxAmount = taxableItem.pre_tax_amount * nonCardPaymentRatio;
          cashTaxApplied = true;
        }
        const taxAmount = multiplyCurrency(paymentPreTaxAmount, tax.amount, order.currency);
        if (tax.included_in_price) {
          taxableItem.total_included_tax += taxAmount;
        } else {
          taxableItem.total_additional_tax += taxAmount;
        }
      }); 
      if(!cashTaxApplied || !cardTaxApplied) {
        console.warn("Tax is possibly missing, cashTaxApplied: ", cashTaxApplied, "cardTaxApplied: ", cardTaxApplied)
      }
    } else {
      const applicableTaxes = getApplicableTaxCategoryTaxes(taxableItem.tax_category_id, false, locationTaxes);
      resetTaxableItemPreTaxAmount(taxableItem, order, locationTaxes, isCardPayment, applicableTaxes);
      applicableTaxes.forEach(tax => {
        const taxAmount = multiplyCurrency(taxableItem.pre_tax_amount, tax.amount, order.currency);
        if (tax.included_in_price) {
          taxableItem.total_included_tax += taxAmount;
        } else {
          taxableItem.total_additional_tax += taxAmount;
        }
      });  
    }
  }

  return taxableItem.tax = addCurrency(taxableItem.total_additional_tax, taxableItem.total_included_tax, order.currency);
};

export const getTaxableItemTotalAdditionalTax = (taxableItem) => (
  taxableItem.total_additional_tax ?? taxableItem.tax ?? 0
)

export const getTaxableItemTotalIncludedTax = (taxableItem) => (
  taxableItem.total_included_tax ?? 0
)

export const getTaxableItemTotalWithTax = (taxableItem) => (
  taxableItem.pre_tax_amount + getTaxableItemTotalAdditionalTax(taxableItem) + getTaxableItemTotalIncludedTax(taxableItem)
);

export const getTaxableItemTotalTax = (taxableItem) => (
  getTaxableItemTotalAdditionalTax(taxableItem) + getTaxableItemTotalIncludedTax(taxableItem)
);

export const getTaxableItemTaxRate = (taxableItem) => (
  getTaxableItemTotalTax(taxableItem) / taxableItem.pre_tax_amount
);

export const getOrderTotalDiscount = (order) => {
  if(order.discount_total) return order.discount_total;
  let totalDiscount = order.discount ?? 0;
  if(order.itemDiscount ) totalDiscount += order.itemDiscount;
  if(order.voucher_discount) totalDiscount += order.voucher_discount;

  return totalDiscount;
}

export const getOrderDiscountableAmount = (order) => {
  if (order.discountable_amount) return order.discountable_amount;

  if (order.total_additional_tax) {
    return order.accepted_price - order.total_additional_tax + getOrderTotalDiscount(order);
  }
  return order.accepted_price - order.tax + getOrderTotalDiscount(order);
}

export const getOrderDiscountedAmount = (order) => {
  //TODO item_total should be renamed discountable_amount for consistency with backend
  const taxableAmount = getOrderDiscountableAmount(order) - getOrderTotalDiscount(order) //+ order.total_fees
  return taxableAmount > 0 ? taxableAmount : 0 
}

export const getOrderTotalAdditionalTax = (order) => (
  getTaxableItemTotalAdditionalTax(order)
)

export const getOrderTotalIncludedTax = (order) => (
  getTaxableItemTotalIncludedTax(order)
)