import React from 'react';
import Grid from '@mui/material/Grid';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { I18nRails } from "../../../shared/rails-i18n-js";
import { calculatePaymentCharges } from '../Model/basket';


const template = ({
  basket,
  radioValue,
  onRadioChange,
}) => {
  return (
    basket && basket.location && basket.location.available_places_payment_methods &&
    basket.location.available_places_payment_methods.map((places_payment_method) => (
      <FormControl component="fieldset">
        <RadioGroup
          name={places_payment_method.admin_name}
          value={radioValue}
          onChange={(e) => onRadioChange(e, places_payment_method)}
        >
          {(() => {
            const fee = calculatePaymentCharges(basket, places_payment_method);
            if (fee > 0) {
              return (
                <FormControlLabel
                  value={places_payment_method}
                  control={<Radio />}
                  label={places_payment_method.name + " (Fee: " + I18nRails.numberToCurrency(fee, { unit: places_payment_method.tx_fee_currency || basket.location.currency_symbol }) + ")"}
                />
              );
            } else {
              return (
                <FormControlLabel
                  value={places_payment_method}
                  control={<Radio />}
                  label={places_payment_method.name}
                />
              );
            }
          })()}
        </RadioGroup>
      </FormControl>
    ))
  );
};

export default template;



