import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router";
import AppLayout from "./AppLayout";
import { filterPhone } from "../filters";
import { I18nRails } from "../../shared/rails-i18n-js";
import * as rh from "./helpers/routes";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { loadImage } from "./helpers/utils";
import { location_header } from "../models/location";
import withParent from "./hoc/withParent";
import withOrder from "./hoc/withOrder";
import OrderResource from "../resources/order";
import CartService from "../services/CartService";
import { useSsrProps } from "../SsrContext";

const AppOrder = ({
  orders,
  order,
  setOrder,
  fdOrder,
  fndLocation,
  listenForUpdate,
  user,
  ...other
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const params = useParams();
  const order_id = params.id;
  const cancelOrder = useRef(undefined);
  const reorder = useRef(undefined);

  useEffect(() => {
    if (!!order_id && !order) {
      listenForUpdate(order_id);
    } else if (!!fdOrder) {
      const order = new this.Order(JSON.parse(fdOrder));
      setOrder(order);
      listenForUpdate(order.id);
    } else if (!!order_id) {
      const cartService = CartService.get(Rails, localStorage);
      const order = cartService.findOrderId(order_id);
      if (order != undefined) {
        setOrder(order);
        //TODO we should not be clearing cart for order location
        //however for now this is done for backward compatibility as when offsite
        //payment (via paypal/ubl/etc) is made, the basket should get cleared out.
        //since right after payment, the order page is shown, we are clearing the cart
        //RIGHT solution will be to add a controller for CompletedPayments where basket
        //get cleared on successful payment
        cartService.clearCartForOrderLocation(order);
      } else {
        const Order = OrderResource.get(Rails);

        Order.get(
          { id: order_id },
          (order) => {
            this.setState({ order });
            setOrder(order);
            cartService.clearCartForOrderLocation(order);
            listenForUpdate(order_id);
          },
          (failure) => {
            alert("Error fetching order. Please refresh");
          }
        );
      }
    }
  }, [])
  console.log("this state order", order);
  return (
    (<AppLayout user={user} {...other}>
      {order && (
        <Grid container>
          <Grid size={12}>
            {fndLocation && location_header(fndLocation) && (
              <div className="dashboard-bg-img" style={{backgroundImage: 'url(' + location_header(fndLocation) + ')'}} />
            )}
            {!fndLocation || !location_header(fndLocation) ? (
              <div className="dashboard-bg-img" style={{backgroundImage: 'url(' + loadImage("banner3.jpg") + ')'}}>
                <Grid container>
                  <Grid size={5}>
                    <p className="order-subheading no-margin">
                      {I18nRails.t("Web.order_confirmation")}
                    </p>
                    <h2 style={{marginTop: 1}}>
                      {I18nRails.t("Web.thankyou_foodnerd")}
                    </h2>
                  </Grid>
                </Grid>
              </div>
            ) : null}
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid style={{textAlign: 'center'}} size={12}>
          {order && (
            <p style={{textAlign: 'center'}}>
              {I18nRails.t('client.progress.waiting_for_restaurant_accept')}
            </p>
          )}
        </Grid>
        {order && 
        <Card elevation={7} style={{margin: '3%', padding: '2%'}}>
          <Grid container>
            <Grid size={3}>
              <p className="order-headFont">{I18nRails.t("Web.res_name")}</p>
            </Grid>
            <Grid size={9}>
              <Link
                className="order-bodyFont btn-flat"
                to={rh.show_location({location_id: order.location.slug})}
              >
                {order.location.name}
              </Link>
              <a href={'tel:' + filterPhone(order.location.phone)}>
                <i className="green-text fas fa-2x fa-phone-square" />
              </a>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={3}>
              <p className="order-headFont">{I18nRails.t("Web.item_name")}</p>
            </Grid>
            <Grid size={9}>
              {order.order_items.map((order_item, index) => (
                <div key={'oi-'+index}>
                  {order_item.item_type === "MenuItem" && order_item.menu_item.description && !order_item.component_option_value && (
                    <p suppressHydrationWarning className="order-bodyFont">
                      {order_item.weighted_number}x {order_item.menu_item.title} ({order_item.menu_item.description})
                    </p>
                  )}
                  {order_item.item_type === "MenuItem" && order_item.menu_item.description && order_item.component_option_value && (
                    <p suppressHydrationWarning className="order-bodyFont">
                      {order_item.weighted_number}x {order_item.menu_item.title}, {order_item.component_option_value.option_name} ({order_item.menu_item.description})
                    </p>
                  )}
                  {order_item.item_type === "MenuItem" && !order_item.menu_item.description && order_item.component_option_value && (
                    <p suppressHydrationWarning className="order-bodyFont">
                      {order_item.weighted_number}x {order_item.menu_item.title}, {order_item.component_option_value.option_name}
                    </p>
                  )}
                  {order_item.item_type === "MenuItem" && !order_item.menu_item.description && !order_item.component_option_value && (
                    <p suppressHydrationWarning className="order-bodyFont">
                      {order_item.weighted_number}x {order_item.menu_item.title}
                    </p>
                  )}
                  {order_item.order_item_ingredients.map((order_item_ingredient, index) => (
                    order_item_ingredient.menu_item_ingredient.customizable && (
                      <div key={'mi-oii-'+index} className="chip green white-text recent_order_ingredients">
                        <span className="order-txt2">{order_item_ingredient.number}x {order_item_ingredient.menu_item_ingredient.ingredient.name}</span>
                      </div>
                    )
                  ))}
                </div>
              ))}
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={3}>
              <p className="order-headFont">{I18nRails.t("Web.order_status")}</p>
            </Grid>
            <Grid size={9}>
              {order.isPendingAcceptance() && (
                <div className="pending-order-acceptance">
                  <i className="fas fa-spinner fa-pulse"></i>
                  {order.isPendingAcceptance() && (
                    <p className="order-bodyFont">{I18nRails.t('client.progress.waiting_for_restaurant_accept')}</p>
                  )}
                  {order.isCancelled() && (
                    <p className="order-bodyFont">{I18nRails.t('client.recents.order_cancelled')}</p>
                  )}
                  {order.isAccepted() && order.ready_in && (
                    <p className="order-bodyFont">{I18nRails.t('client.recents.order_accepted')}</p>
                  )}
                  {order.isRejected() && (
                    <p className="order-bodyFont">{order.annotation}</p>
                  )}
                  {order.state === "ready" && (
                    <p className="order-bodyFont">{I18nRails.t('client.recents.order_ready')}</p>
                  )}
                </div>
              )}
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={3}>
              <p className="order-headFont">{I18nRails.t("Web.date")}</p>
            </Grid>
            <Grid size={9}>
              <p suppressHydrationWarning className="order-bodyFont">{I18nRails.l('date.formats.medium', order.received_at)}</p>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={3}>
              <p className="order-headFont">{I18nRails.t("Web.price")}</p>
            </Grid>
            <Grid size={9}>
              <div className="price-mobile">
                {!!order.discount && order.discount > 0 && (
                  <>
                    <span className="discounted-actual">{I18nRails.numberToCurrency(order.actual_price, { unit: order.location.currency_symbol })}</span>
                    <span className="discount-final">{I18nRails.numberToCurrency(order.final_price, { unit: order.location.currency_symbol })}</span>
                  </>
                )}
                {order.discount <= 0 && (
                  <>
                    <br />
                    {I18nRails.numberToCurrency(order.final_price, { unit: order.location.currency_symbol })}
                  </>
                )}
              </div>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid style={{marginTop: '2%'}} size={3}>
              {!order.isCancelled() && !order.isRejected() && !order.isAccepted() && !order.isCompleted() ? (
                <Button className="btn-red" onClick={(e) => cancelOrder.current(order)}>
                  {I18nRails.t('client.progress.cancel_order')}
                </Button>
              ) : (
                <>
                  <Button className="btn-dark" onClick={(e) => cancelOrder.current(order)}>
                    {I18nRails.t('client.progress.cancel_order')}
                  </Button>
                  <Button className="btn-red" onClick={(e) => reorder.current(order)} style={{cursor: 'pointer'}}>
                    {I18nRails.t('client.progress.reorder')}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Card>}
        <Card elevation={7} style={{margin:'3%'}}>
          <Grid container style={{margin:'4%'}}>
            <Grid size={2}>
              <img className="responsive-img" src={loadImage("food.png")} />
            </Grid>
            <Grid size={5}>
              <img className="responsive-img" src={loadImage("curvy-dotted-line.png")} />
            </Grid>
            <Grid style={{textAlign:'center', marginTop:'4%'}} size={4}>
              <Link to={rh.show_orders()} className="more-orders">
                {I18nRails.t('Search.Checkout.Awaiting.Completed')}
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </AppLayout>)
  );
};

export default withParent(withOrder(AppOrder));
