import React, { useState, useRef, useEffect } from 'react';


import AppLayout from './AppLayout';

import _updates_filters from './templates/_updates_filters';
import _updates from './templates/_updates';
import withParent from './hoc/withParent';
import { useSsrProps } from '../SsrContext';


const AppUpdates = ({
  user,
  initUser,
  setUser,
  setLatLng,
  ...others
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const [locations0, setLocations] = useState([]);
  const [favorites, setFavorites] = useState();
  const User = useRef(undefined);
  const FavoritesFactory = useRef(undefined);
  const favorites0 = useRef(undefined);
  const locations1 = useRef(undefined);
  const GeolocationFactory = useRef(undefined);

  useEffect(() => {
    initUser(user => {
      setUser(user);
      FavoritesFactory.current.query({},
        favorites => {
          let locations = locations0;
          let i = 0;
          while (i < favorites0.current.length) {
            if (favorites0.current[i].obj.highlights.length > 0) {
              locations1.current.push(favorites0.current[i].obj);
            }
          }
          setFavorites(favorites);
          setLocations(locations);
        });

      GeolocationFactory.current.get(function(latLng) {
        setLatLng(latLng);
      });
    });
  }, []);

  return (
    <AppLayout user={user} {...others}>
      <main>
        <div className="content-area">
          <aside id="left-sidebar-nav" />
          <section id="content">
            <h2 className="updates-heading">Updates</h2>
            <UpdatesFilters component={TODO_this} />
            <Updates component={TODO_this} />
          </section>
        </div>
      </main>
    </AppLayout>
  );
};

export default withParent(AppUpdates);
