import { storage } from "../../react/bridge";
import { isEmpty } from "./utils";
import {BASE_URL} from '../constants';
export const getApiHeaders = (headers) => {
  const isOrderTaker = process.env.EXPO_PUBLIC_APP_TYPE === 'order-taker';
  if(isOrderTaker) {
    return getOrderTakerHeaders(headers)
  }
  return getDeliveryHeaders(headers);
  // getOrderTakergetDeliveryHeaders() : getApiHeaders
} 

export const getDeliveryHeaders = (headers) => {
  const device_token = storage.getItem('device_token')
  const access_token = storage.getItem('access_token')
  const data = {
    'Content-Type': 'application/json',
    ...headers,
  };
  if (access_token) {
    data['Authorization'] = `Bearer ${access_token}`;
  }
  if (device_token) {
    data['Device-Token'] = device_token;
    data['Device-Type'] = Platform.OS;
  }
  data['Time-Zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return data;
};

export const getOrderTakerHeaders = (headers) => {
  if(!headers || !headers.Authorization) {
    const accessToken = storage.getItem("access_token");
    if(!accessToken) {
      return null; //no headers to be added without authentication
    }
    // console.log("Authorization: ", accessToken)
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    }  
  }
  const deviceData = storage.getItem("deviceGlobal");
  const device = JSON.parse(deviceData)
  if (device.device_token) {
    headers = {
      ...headers,
      "Device-Token": device.device_token
    }
  }
  return {
    "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    "Device-ID": device.identifier,
    "Device-App": "order-taker",
    "Device-Type": device.kind,
    ...headers,
  }
}

export const fetchIsOk = response =>
  response.ok
    ? response.json()
    : Promise.reject(new Error('Failed to load data from server'));

export const fetchResponse = response =>
  response.json()

export const emptyStartAction = (type) => ({
  type,
});

export const startAction = (type) => ({
  type,
});

export const passAction = (type, msg, data) => ({
  type,
  msg,
  data,
});

export const failAction = (type, msg) => ({
  type,
  msg,
});

export const errorMessage = (error) => {
  let msg = '';
  console.error('in errors --> ', error);
  if (error.response.data.full_messages) {
    msg = error.response.data.full_messages;
  }else if (error.response.data.errors) {
    msg = error.response.data.errors.join("/n");
  } else if (error.request) {
    if (error.message === 'Network Error') {
      msg = 'No internet connection';
    } else if (error.request.status == 401) {
      msg = 'You are not authorized to access.';
    } else if (error.request.status == 500 || error.request.status == 404) {
      msg = "Server Error: We're sorry, but something went wrong. Try again";
    } else {
      const code = error.request.status;
      msg = HTTPStatusCode.code ? `${HTTPStatusCode[code]}` : null;
    }
  } else {
    msg = error.message;
  }
  // Sentry.captureException(new Error(msg), {
  //   logger: msg,
  // });
  return msg;
};

export const generateFullErrorMessages = (errors) => {
  var errorMessages = {};
  for (var key in errors) {
    let error_info_message = errors[key];
    if (key.includes('phone_number')) {
      key = 'Phone number';
    }
    if (key.includes('currentPassword')) {
      key = 'current password';
    }
    const msg = `${key.split('_').join(' ')} ${error_info_message}`;
    
    errorMessages[key] = `${msg.charAt(0).toUpperCase()}${msg.slice(1).toLowerCase()}`    ;
  }
  return errorMessages;
};

export const paginationlinks = (link) => {
  link = link.replace(/"|,| /g, '');
  link = link.replace(/;rel=/g, '');
  link = link.split(/<(.*?)>/g);
  const links = [];
  link.map((val) => {
    if (!isEmpty(val)) {
      links.push(val);
    }
  });
  var rv = {};
  const linkA = [],
    keyA = [];
  links.map((val, key) => {
    if (key % 2 === 0) {
      linkA.push(val);
    } else {
      keyA.push(val);
    }
  });
  keyA.map((val, key) => {
    rv[val] = linkA[key];
  });
  return rv;
};

export const favoriteDishesExtractor = (data) => {
  return data.map((data) => {
    return {
      id: data.obj.id,
      name: data.obj.title,
      type: data.obj_type,
      slug: data.obj.slug,
      url_main_image: data.obj.url_image1,
      url_small_logo:
        data.obj.hosted_datas &&
        data.obj.hosted_datas.length > 0 &&
        data.obj.hosted_datas[0].url_small_logo
          ? data.obj.hosted_datas[0].url_small_logo
          : `${BASE_URL}/packs/media/images/Loc1-6d839bf0.jpg`,
      favorites_count: data.obj.favorites_count,
    };
  });
};
export const favoriteDataExtractor = (data) => {
  return data.map((data) => {
    const lat = data.obj.lat && data.obj.lat ? data.obj.lat : '';
    const lng =
      data.obj.addresses && data.obj.lng && data.obj.lng
        ? data.obj.lng
        : '';
    return {
      id: data.obj_id,
      name: data.obj.name,
      title: data.obj.title,
      type: data.obj_type,
      lat,
      lng,
      latlng: lat && lng ? `${lat},${lng}` : null,
      slug: data.obj.slug,
      // open_closed_time: data.open_closed_time,
      url_small_logo:
        data.obj.hosted_datas &&
        data.obj.hosted_datas.length > 0 &&
        data.obj.hosted_datas[0].url_small_logo
          ? data.obj.hosted_datas[0].url_small_logo
          : `${BASE_URL}/packs/media/images/Loc1-6d839bf0.jpg`,
      url_main_image:
        data.obj.hosted_datas &&
        data.obj.hosted_datas.length > 0 &&
        data.obj.hosted_datas[0].url_main_image
          ? data.obj.hosted_datas[0].url_main_image
          : `${BASE_URL}/packs/media/images/Loc1-6d839bf0.jpg`,
      // local_address:
      //   has(data, "addresses") &&
      //   has(data.addresses[0], "local_address") &&
      //   data.addresses[0].local_address
      //     ? data.addresses[0].local_address
      //     : "",
      verified: data.obj.verified,
      online_booking: data.obj.online_booking,
      favorites_count: data.obj.favorites_count,
      currency: data.obj.currency,
      state: data.obj.state,
      position: data.obj.position,
      // deliveries: deliveries,
      // channels: channels
    };
  });
};

export const locationsDataExtractor = (locations) => {
  return locations.map((location) => {
    const channels = [];
    const offers = [];

    if (
      location.vouchers_discount_type &&
      location.vouchers_discount_type === 'fixed'
    ) {
      offers.push('Flat PKR ' + location.vouchers_value + ' Off');
    }
    if (
      location.vouchers_discount_type &&
      location.vouchers_discount_type === 'percentage'
    ) {
      offers.push('Upto ' + location.vouchers_value + '% Off');
    }
    if (location.offers.length >= 1) {
      location.offers.map((offer) => {
        offers.push(offer.title);
      });
    }
    location.channels.map((v) => {
      if (v.name == 'take_away') {
        channels.push('Take away');
      }
      if (v.name == 'eat_in') {
        channels.push('Eat in');
      }
      if (v.name == 'delivery') {
        channels.push('Delivery');
      }
    });
    const lat =
    location.addresses?.[0] &&
    location.addresses[0].lat &&
    location.addresses[0].lat
        ? location.addresses[0].lat
        : '';
    const lng =
    location.addresses?.[0] &&
    location.addresses[0].lng &&
    location.addresses[0].lng
        ? location.addresses[0].lng
        : '';
    return {
      id: location.id,
      name: location.name,
      offers: offers.join(' '),
      lat,
      phone: location.phone,
      lng,
      latlng: lat && lng ? `${lat},${lng}` : null,
      slug: location.slug,
      open_closed_time: location.open_closed_time,
      url_small_logo:
        location.hosted_datas &&
        location.hosted_datas.length > 0 &&
        location.hosted_datas[0].url_small_logo
          ? location.hosted_datas[0].url_small_logo
          : `${BASE_URL}/packs/media/images/Loc1-6d839bf0.jpg`,
      url_main_image:
        location.hosted_datas &&
        location.hosted_datas.length > 0 &&
        location.hosted_datas[0].url_main_image
          ? location.hosted_datas[0].url_main_image
          : `${BASE_URL}/packs/media/images/Loc1-6d839bf0.jpg`,
      local_address:
        location.addresses?.[0] &&
        location.addresses[0].local_address &&
        location.addresses[0].local_address
          ? location.addresses[0].local_address
          : '',
      verified: location.verified,
      online_booking: location.online_booking,
      favorites_count: location.favorites_count,
      currency: location.currency,
      // deliveries: deliveries,
      channels: channels,
      position: location.position,
    };
  });
};

export const locationInsidePolygon = (point, vs) => {
  var x = point[0],
    y = point[1];

  let inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0],
      yi = vs[i][1];
    var xj = vs[j][0],
      yj = vs[j][1];

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

export const locationDataExtractor = (data) => {
  
  if (isEmpty(data)) return;
  const channels = [];
  const offers = [];
  if (data.offers.length >= 1) {
    data.offers.map((offer) => {
      offers.push(offer.title);
    });
  }
  data.channels.map((v) => {
    if (v.name == 'take_away') {
      channels.push('Take away');
    }
    if (v.name == 'eat_in') {
      channels.push('Eat in');
    }
    if (v.name == 'delivery') {
      channels.push('Delivery');
    }
  });
  // const deliveries = data.deliveries.map(v => {
  //   if (v.geo_polygons) {
  //     // console.log(data.id, v.geo_polygons);
  //     let geo_polygons = JSON.parse(v.geo_polygons);
  //     geo_polygons = JSON.stringify(geo_polygons);
  //     geo_polygons = JSON.parse(geo_polygons);
  //     return {
  //       id: v.id,
  //       place_id: v.place_id,
  //       delivery_price: v.delivery_price,
  //       min_delivery_amount: v.min_delivery_amount,
  //       geo_polygons: geo_polygons.features[0].geometry.coordinates[0],
  //       name: v.name
  //     };
  //   }
  // });
  const lat =
    data.addresses &&
    data.addresses[0].lat &&
    data.addresses[0].lat
      ? data.addresses[0].lat
      : '';
  const lng =
    data.addresses &&
    data.addresses[0].lng &&
    data.addresses[0].lng
      ? data.addresses[0].lng
      : '';
  return {
    id: data.id,
    name: data.name,
    offers: offers.join(' '),
    lat,
    phone: data.phone,
    lng,
    latlng: lat && lng ? `${lat},${lng}` : null,
    slug: data.slug,
    open_closed_time: data.open_closed_time,
    url_small_logo:
      data.hosted_datas &&
      data.hosted_datas.length > 0 &&
      data.hosted_datas[0].url_small_logo
        ? data.hosted_datas[0].url_small_logo
        : `${BASE_URL}/packs/media/images/Loc1-6d839bf0.jpg`,
    url_main_image:
      data.hosted_datas &&
      data.hosted_datas.length > 0 &&
      data.hosted_datas[0].url_main_image
        ? data.hosted_datas[0].url_main_image
        : `${BASE_URL}/packs/media/images/Loc1-6d839bf0.jpg`,
    local_address:
      data.addresses &&
      data.addresses[0].local_address &&
      data.addresses[0].local_address
        ? data.addresses[0].local_address
        : '',
    verified: data.verified,
    online_booking: data.online_booking,
    favorites_count: data.favorites_count,
    currency: data.currency,
    // deliveries: deliveries,
    channels: channels,
    position: data.position,
  };
};