export var location_base = function(params, Rails) {
  if (!!Rails && !!Rails.domain_place_id && (Rails.domain_place_type === 'Location')) {
    return '';
  } else {
    return `/locations/${params.location_id || params.id}`;
  }
};

export var show_location = function(params, Rails){
  if (!!Rails && !!Rails.domain_place_id && (Rails.domain_place_type === 'Location')) {
    return "/";
  } else {
    return `/locations/${params.location_id || params.id}`;
  }
};

export var location_page = (params, Rails) => `${location_base(params, Rails)}/pages/${params.page_id}`;

export var location_blogs = (params, Rails) => `${location_base(params, Rails)}/blogs`;

export var location_blog = (params, Rails) => `${location_base(params, Rails)}/blogs/${params.blog_id}`;

export var location_new_blog = (params, Rails) => `${location_base(params, Rails)}/blogs/new`;

export var location_new_page = (params, Rails) => `${location_base(params, Rails)}/pages/new`;

export var location_reviews = (params, Rails) => `${location_base(params, Rails)}/reviews`;

export var location_cart = (params, Rails) => `${location_base(params, Rails)}/cart`;

export var location_confirm = (params, Rails) => `${location_base(params, Rails)}/confirm`;

export var location_about = (params, Rails) => `${location_base(params, Rails)}/about`;

export var location_order_history = (params, Rails) => `${location_base(params, Rails)}/orders`;

export var show_locations = () => "/locations";

export var show_order = order => `/orders/${order.number}`;
export const show_order_payment_form = order => `/orders/${order.number}`
export var show_orders = () => "/orders";

export var login = () => "/login";