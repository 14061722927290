import React from "react";
import { Link } from "react-router";

import { I18nRails } from "../../shared/rails-i18n-js";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { resetBasketTotals } from "./Model/basket";
import { orderItemDisplayDescription, orderItemDisplayTitle } from "../models/order_item";
import CartService from "../services/CartService";
import { useSsrProps } from "../SsrContext";


const StickyCart = ({
  basket,
  changeQuantity,
  removeBasketItem,
}) => {
  const ssrProps = useSsrProps();
  const {Rails} = ssrProps;

  const cartService = CartService.get(Rails, localStorage);
  resetBasketTotals(basket, cartService);
  return (!!basket && (<div style={{ position: 'sticky', top: '75px' }}>
    <Grid container style={{ position: 'sticky', zIndex: '100', padding: 5 }} justify="center" alignContent="center">
      <Grid size={6}>
        <p className="subhead-font">
          <i className="material-icons center subHeadIcons first-color">restaurant</i>
          { I18nRails.t("Web.your_order_is_here") }
        </p>
      </Grid>
      <Grid size={12}>
        <Divider />
        {basket.location.deliveries[0] && basket.location.deliveries[0].min_delivery_amount && (
          <p>
            { I18nRails.t("Web.min_order") } : Rs. { basket.location.deliveries[0].min_delivery_amount } /-
          </p>
        )}
      </Grid>
      <Grid container className="stickycart-container">
        {basket.items.map((item, index) => (
          (item.type == "OrderItem" || item.price > 0) && (
            <>
              <Grid style={{ paddingRight: '0px', paddingLeft: '5px' }} size={6}>
                <p className="zero-margin">{ orderItemDisplayTitle(item) }</p>
              </Grid>
              <Grid
                style={{ paddingRight: '0px', paddingLeft: '3px', marginTop: '15px', marginBottom: '15px' }}
                size={3}>
                <div>
                  <i
                    className="material-icons"
                    style={{ fontSize: '20px', color: 'red', cursor: 'pointer', paddingTop: '1px', verticalAlign: 'sub' }}
                    onClick={(e) => changeQuantity(item, 'remove')}
                  >
                    remove
                  </i>
                  <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                    { item.weighted_number }
                  </span>
                  <i
                    className="material-icons"
                    style={{ fontSize: '19px', color: 'green', cursor: 'pointer', paddingTop: '1px', verticalAlign: 'sub' }}
                    onClick={(e) => changeQuantity(item, 'add')}
                  >
                    add
                  </i>
                </div>
              </Grid>
              <Grid style={{ paddingRight: '0px', paddingLeft: '2px' }} size={2}>
                <p className="zero-margin">
                  { item.price ? item.price : Number(item.menu_item.price) }
                </p>
              </Grid>
              <Grid style={{ paddingRight: '0px', paddingLeft: '0px' }} size={1}>
                <div>
                  <i
                    className="material-icons"
                    onClick={(e) => removeBasketItem(item)}
                    style={{ fontSize: '16px', color: 'red', cursor: 'pointer', marginTop: '20px' }}
                  >
                    cancel
                  </i>
                </div>
              </Grid>
              <Grid size={9}>
                <sub>{ orderItemDisplayDescription(item) }</sub>
              </Grid>
            </>
          )
        ))}
        {basket.totals.numberOfCartItems > 0 && (
          <>
            {basket.totals.gst > 0 && (
              <>
                <Grid style={{ paddingLeft: '3px' }} size={9}>
                  <p>
                    <i style={{fontSize:'20px'}} className="material-icons center subHeadIcons second-color">attach_money</i>
                    GST
                  </p>
                </Grid>
                <Grid size={3}>
                  <p>{basket.totals.gst}</p>
                </Grid>
              </>
            )}
            {basket.totals.discount > 0 && (
              <>  
                <Grid size={9}>
                  <p>
                    <i style={{fontSize:'20px'}} className="material-icons center subHeadIcons second-color">monetization_on</i>
                    {I18nRails.t("Web.total_discount")}
                  </p>
                </Grid>
                <Grid size={3}>
                  <p className="zero-margin">{basket.totals.discount}</p>
                </Grid>
              </>
            )}
            {basket.totals.delivery_charges > 0 && (
              <>
                <Grid size={9}>
                  <p>
                    <i style={{fontSize:'20px'}} className="material-icons center subHeadIcons second-color">motorcycle</i>
                    {I18nRails.t("Web.delivery_charges")}
                  </p>
                </Grid>
                <Grid size={3}>
                  <p className="zero-margin">
                    {I18nRails.numberToCurrency(
                      basket.totals.delivery_charges,
                      { unit: basket.location.currency_symbol }
                    )}
                  </p>
                </Grid>
              </>
            )}
            {basket.totals.final_price > 0 && (
              <>
                <Grid style={{ paddingLeft: '3px' }} size={8}>
                  <p>
                    <i style={{fontSize:'20px'}} className="material-icons center subHeadIcons second-color">monetization_on</i>
                    {I18nRails.t("Web.total_amount")}
                  </p>
                </Grid>
                <Grid style={{ paddingLeft: '7%' }} size={4}>
                  <p>
                    {I18nRails.numberToCurrency(
                      basket.totals.final_price,
                      { unit: basket.location.currency_symbol }
                    )}
                  </p>
                </Grid>
              </>
            )}
            <Divider />
          </>
        )}
      </Grid>
      {basket.totals.numberOfCartItems > 0 && (
        <Link
          to={'/locations/' + basket.location_id + '/cart'}
          className="checkout-btn .btn btn-second"
        >
          {I18nRails.t("Web.checkout")}
        </Link>
      )}
    </Grid>
  </div>));  
}

export default StickyCart;
