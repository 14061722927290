import React, { useState, useEffect } from "react";
import { I18nRails } from "../../shared/rails-i18n-js";
import LocationCard from "./LocationCard";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

const InfiniteLocations = ({
  loadMoreLocations,
  items,
  initUser,
  isLiked,
  toggleLike,
  loading,
  latLng,
  isDataFetched,
  user
}) => {
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", onDocumentScroll);
    return () => {
      document.removeEventListener("scroll", onDocumentScroll);
    }
  }, []);

  const onDocumentScroll = (e) => {
    e.stopPropagation();
    const documentElement = document.documentElement;    
    const scrollTop =
      (window.pageYOffset || documentElement.scrollTop) -
      (documentElement.clientTop || 0);
    let condition =
      scrollTop + documentElement.clientHeight + 900 >=
      documentElement.scrollHeight;
    condition = condition && !loading && !isDataFetched;

    if (condition) {
      setLoadingMore(true);
      loadMoreLocations();
    }
  };

  var sortedLocations = [];
  if (items) {
    sortedLocations = items.sort(function(a, b) {
      return b[0] - a[0];
    });
  }

  return <>
    {loading && !loadingMore && (
      <>
        <CircularProgress color="primary" style={{ marginLeft: '3%', marginBottom: '1%' }} />
        <p style={{ marginLeft: '3%', marginBottom: '5%' }}>{I18nRails.t('FindMenu')}</p>
      </>
    )}
    <Grid container spacing={4} style={{ paddingBottom: '5%', paddingLeft: '3%', paddingRight: '3%', width: '100%' }}>
      {sortedLocations.length > 0 &&
        sortedLocations.map((fdLocation, index) => (
          <Grid
            key={'grid' + fdLocation.slug}
            size={{
              xs: 12,
              md: 4,
              lg: 4,
              sm: 12
            }}>
            <LocationCard
              isLiked={isLiked}
              toggleLike={toggleLike}
              user={user}
              fndLocation={fdLocation[0]}
              latLng={latLng}
            />
          </Grid>
        ))}
      {sortedLocations.length <= 0 && !loading && (
        <p style={{ marginLeft: '1rem' }}>{I18nRails.t('NoResult')}</p>
      )}
    </Grid>
    <Grid container>
      <Grid size={12}>
        {loading && loadingMore && (
          <>
            <CircularProgress color="primary" style={{ marginLeft: '3%', marginBottom: '1%' }} />
            <p style={{ marginLeft: '3%', marginBottom: '5%' }}>Loading more..</p>
          </>
        )}
      </Grid>
    </Grid>
  </>;
};

export default InfiniteLocations;
