import React, { useState, useRef, useEffect } from 'react';

import LocationLayout from './LocationLayout';
import LocationBlogResource from '../resources/location-blog';
import * as rh from './helpers/routes';
import { Link } from "react-router";
import { I18nRails } from '../../shared/rails-i18n-js';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Pagination from "@mui/material/Pagination";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { loadImage } from './helpers/utils';
import withParent from './hoc/withParent';
import { useSsrProps } from '../SsrContext';


const AppLocationBlogs = ({
  basket,
  setBasket,
  initLocation,
  user,
  initUser,
  fndLocation,
  setLocation,
  setLatLng,
  changeQuantity,
  fdLocationBlogs,
  locationId,
  setLocationId,
  removeBasketItem,
  toggleLike,
  numberOfCartItems,
  selectedCountry,
  changeCountry,
  countryConfig,
  ...other
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const [page, setPage] = useState(1);
  const [setPage0, setSetPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [noOfPages, setNoOfPages] = useState(6);
  const [location_blogs, setLocation_blogs] = useState();

  const handleChange = (event, value) => {
    setPage(value);
  };


  useEffect(() => {
    initLocation((location) => {
      setBasket({ ...basket, location: location });
      setLocation(location);
      setLocationId(location.slug);
    });

    const LocationBlog = LocationBlogResource.get(Rails);
    if (!fdLocationBlogs) {
      LocationBlog.query({ location_id: locationId }, location_blogs => {
        console.log('this.location_blogs', location_blogs);
        setLocation_blogs(location_blogs);
        setNoOfPages(Math.ceil(location_blogs.length / itemsPerPage));
      });
    } else {
      const location_blogs = JSON.parse(fdLocationBlogs).map(location_blog => new LocationBlog(location_blog));
      setLocation_blogs(location_blogs);
      setNoOfPages(Math.ceil(location_blogs.length / itemsPerPage));
    }
  }, []);

  return (
    <LocationLayout 
      user={user}
      initUser={initUser}
      fndLocation={fndLocation}
      basket={basket}
      changeQuantity={changeQuantity}
      removeBasketItem={removeBasketItem}
      toggleLike={toggleLike}
      numberOfCartItems={numberOfCartItems}
      selectedCountry={selectedCountry}
      changeCountry={changeCountry}
      countryConfig={countryConfig}
      setLatLng={setLatLng}
      {...other}
    >
      {(!!location_blogs && location_blogs.length > 0) ? (
        <>
          <Grid size={{xs: 12}}>
            <Grid container justify="center" spacing={3}>
              {location_blogs
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((blog) => (
                  <Grid size={{xs: 5}} key={blog.id} className="blog-post">
                    <Card elevation={3} style={{ width: '100%' }}>
                      <CardHeader titleTypographyProps={{ variant: 'h6' }} title={blog.author_name} subheader={I18nRails.l('date.formats.short', blog.created_at)}>

                      </CardHeader>
                      {!!blog.cover_image ?
                        <CardMedia > <img src={blog.cover_image} style={{ height: '260px', width: 'auto' }} /> </CardMedia> :
                        <CardMedia > <img src={loadImage('blog-small-image.jpg')} style={{ height: '250px', width: 'auto' }} /> </CardMedia>
                      }
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h6">
                          <a href={rh.location_blog({ location_id: locationId, blog_id: blog.id }, Rails)}>{blog.title}</a>
                        </Typography>
                        <Typography paragraph color="textSecondary" style={{ 'word-break': 'break-all' }}>
                          {blog.index_page_content}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">
                          <a href={rh.location_blog({ location_id: locationId, blog_id: blog.id })}>Read more ...</a>
                        </Button>
                        <IconButton>
                          <ThumbUpIcon />
                        </IconButton>
                        <span>{blog.favorites_count}</span>
                        <IconButton>
                          <InsertCommentIcon />
                        </IconButton>
                        <span>{blog.reviews_count}</span>
                      </CardActions>
                    </Card>
                  </Grid >
                ))}
            </Grid>
          </Grid>
          <Pagination style={{width:'50%'}} defaultPage={1} count={noOfPages} size="small" color="primary" onChange={handleChange} />
        </>
      ) : (
        <Card style={{backgroundColor:'#f45f5f'}} elevation={3}>
          <p style={{paddingLeft:'2%'}}>
            <b>{ I18nRails.t("Web.no_blogs") }</b>
          </p>
        </Card>
      )}
    </LocationLayout>
  );
};

export default withParent(AppLocationBlogs);
