import { I18nRails } from '../shared/rails-i18n-js';

export function showCount(title, count) {
  if (!!count && (count >= 0)) {
    return `${ title } (${ count })`;
  } else {
    return title;
  }
};

export function showPosition(order_position, show_position, position) {
  if (!!show_position && !!order_position) {
    return `${ order_position }.`;
  } else if (!!show_position && !!position) {
    return `${ position }.`;
  } else {
    return '';
  }
};
export function price(price, showCurrency, currency_symbol) {
  price ||= ''
  if (showCurrency) {
    if (currency_symbol === 'kr') {
      return I18nRails.numberToCurrency(price, { unit: currency_symbol, stripInsignificantZeros: true });
    } else if (!!currency_symbol) {
      return I18nRails.numberToCurrency(price, { unit: currency_symbol });
    } else {
      return I18nRails.numberToRounded(price, { precision: 2, stripInsignificantZeros: true });
    }
  } else {
    return I18nRails.numberToRounded(price, { precision: 2, stripInsignificantZeros: true });
  }
};

export function i18nDate(dt, format) {
  if (!!dt) {
    return I18nRails.l(format, dt);
  }
};

export function date(timestamp) {
  return I18nRails.l('time.formats.short', new Date(timestamp));
}

// TODO: write translate workaround.
export function minmax(title, min, max) {
  if (!!min && (min > 0)) {
    if (!!max && (max > 0) && (max !== min)) {
      return I18nRails.t('client.menuitem.title_minmax', { title, min, max });
    } else if ((max !== min) && (min > 1)) {
      return I18nRails.t('client.menuitem.title_min', { title, min });
    } else { //max == min == 1
      return I18nRails.t('client.menuitem.title_required', { title });
    }
  } else {
    if (!!max && (max > 0)) {
      return I18nRails.t('client.menuitem.title_max', { title, max });
    } else {
      return I18nRails.t('client.menuitem.title_no_minmax', { title });
    }
  }
};

const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export function AvailableDay(input) {
  const today = new Date();
  const input_week_day = input.date.getDay();
  const today_week_day = today.getDay();
  if (input_week_day === today_week_day) {
    return "Today";
  } else {
    return `${ weekDays[input_week_day] }  ${ I18nRails.l('time.formats.future_day', input.date) }`;
  }
};

export function filterPhone(phone) {
  if (!phone) { return phone; }
  const phones = phone.split(/\s+,\s+/, 1);
  return phones[0];
};
