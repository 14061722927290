import React, { useState } from 'react';
import sweetalert2 from 'sweetalert2';
import GiftCardResource from '../resources/gift-card';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { I18nRails } from '../../shared/rails-i18n-js';
import { useSsrProps } from '../SsrContext';


const GiftCardClaim = ({
  showResourceError,
  showResourceResponseError
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const [gift_card0, setGift_card] = useState({
    code: ''
  });

  const claim = (e) => {
    e.preventDefault();
    if (!gift_card0.code) {
      sweetalert2.fire(I18nRails.t('venue.alertmessage.validgift'));
    } else {
      GiftCardResource.get(Rails).update({ code: gift_card0.code }, gift_card => {
        if (!!gift_card.errors) {
          showResourceResponseError(gift_card.errors);
        } else {
          sweetalert2.fire(`${ I18nRails.t('venue.alertmessage.giftcardfor') } ${ gift_card.card.pretty_name } ${ gift_card.card.status }`);
        }
      }
        , error => {
          showResourceError(error);
        });
    }
  };

  const setGiftCardField = (key, e) => {
    setGift_card({ ...gift_card, [key]: e.target.value });
  };

  return (
    <form name='gift_card' onSubmit={claim}>
      <TextField name='gift_card.code' value={gift_card0.code} onChange={(e) => setGiftCardField('code', e)} required type='text'/>
      <br/>
      <br/>
      <Button className='btn-first' style={{padding:'9px 8px'}} type='submit' value='Submit'>
        { I18nRails.t("client.profile.gift_card.submit") }
      </Button>
    </form>
  );
};

export default GiftCardClaim;
