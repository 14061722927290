import React, { useState } from "react";
import { Link } from "react-router";
import { I18nRails } from "../../../shared/rails-i18n-js";
import {date as dateFilter} from "../../filters";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LocationMenuItem from "./MenuItem";
import * as rh from "../helpers/routes";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { getMenuOffer } from "../../models/menu";
import { isLoggedIn, loadImage } from "../helpers/utils";
import { isUserLiked } from "../../models/user";
import { useSsrProps } from "../../SsrContext";

export default function LocationMenu({
  onSubmitReview,
  review_title,
  user,
  fndLocation,
  openReviewModal,
  showReviewDialog,
  setShowReviewDialog,
  closeReviewDialog,
  toggleLike,
  reviews,
  class_for_option_value,
  addToBasketDirect,
  openMenuItemModal
}) {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const [tabValue, setTabValue] = useState(0);

  const children = ({
    values,
    errors,
    handleChange,
    touched,
    isSubmitting,
    isValidating,
  }) => {
    return (
      (<Form method='post' className='login-form'>
        <Grid container>
          <Grid size={9}>
            {/* <Field browser-default(label="Your feedback", style={{width:'100%'}}, maxLength={50}, placeholder={review_title}, onChange={(e) => props.component.setState({reviewContent: e.target.value})}, value={reviewContent}, required, type='text' /> */}
            <Field browser-default name='reviewContent' onChange={handleChange} style={{width:'100%'}} placeholder={review_title} required type='text' />
            <ErrorMessage name='reviewContent' component='validation-message' />
          </Grid>
          <Grid style={{padding:'2%'}} size={3}>
            <Button 
              className='tick-black btn-dark' 
              disabled={Object.keys(errors).length || isSubmitting || isValidating} 
              type='submit'
            >
              Send
              <i className='fas fa-check' aria-hidden='true'/>
            </Button>
          </Grid>
        </Grid>
      </Form>)
    );

  };

  return (<>
    <Grid container>
      <Grid size={12}>
        {!fndLocation || !fndLocation.menus ? (
          <CircularProgress />
        ) : (
          <AppBar position="sticky" sx={{ marginBottom: "3%" }}>
            <Tabs
              value={tabValue}
              onChange={(e, newValue) =>  
                {
                  console.log(fndLocation);
                  setTabValue(newValue)
                }}
              sx={{ backgroundColor: "white", color: "black" }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {fndLocation.menus.map((menu, index) => (
                <Tab
                  component="a"
                  label={menu.title}
                  onClick={(e) => e.preventDefault()}
                  key={index + "ms-" + index}
                  href={"#menu-" + index + "-" + menu.id}
                />
              ))}
            </Tabs>
          </AppBar>
        )}
      </Grid>
      <Grid size={12}>
        <Card elevation={5}>
        {fndLocation.menus.length > 0 && fndLocation.menus[tabValue]?.menu_items?.length > 0 ? (
      <div id={"menu-" + tabValue + "-" + fndLocation.menus[tabValue].id} style={{marginBottom:'6rem'}}>
        <img className="dots responsive-img" alt="" src={loadImage("dots.png")} />
        <p className="menu-heading dots">
          {fndLocation.menus[tabValue].title}
          {getMenuOffer(fndLocation.menus[tabValue]) && (
            <span className="offer-color">{getMenuOffer(fndLocation.menus[tabValue])}</span>
          )}
          <a className="btn-reviews-rest" onClick={(e) => openReviewModal("Menu", fndLocation.menus[tabValue], e)}>
            <i className="fas fa-comment" aria-hidden="true"></i>
          </a>
        </p>
        <img className="dots responsive-img" alt="" src={loadImage("dots.png")} />
        
        {fndLocation.menus[tabValue].menu_items.map((menu_item, index) => (
          <LocationMenuItem
            user={user}
            fndLocation={fndLocation}
            menu_item={menu_item}
            menu={fndLocation.menus[tabValue]}
            index_2={index}
            cindex={menu_item.id}
            openMenuItemModal={openMenuItemModal}
            openReviewModal={openMenuItemModal}                    
            toggleLike={toggleLike}
            key={index + menu_item.id}
            class_for_option_value={class_for_option_value}
            addToBasketDirect={addToBasketDirect}
          />
        ))}
      </div>
    ) : (
      <p>No menu items available.</p>
    )}
        </Card>
      </Grid>
    </Grid>
    <Dialog 
      open={showReviewDialog} 
      onClose={closeReviewDialog} 
      fullWidth={true} 
      maxWidth='sm'
    >
      <DialogTitle sx={{paddingBottom:'0px'}}>
        <Grid container>
          <Grid size={11}>
            <h5 sx={{fontWeight:'600'}}>{review_title}</h5>
          </Grid>
          <Grid sx={{textAlign:'right'}} size={1}>
            <IconButton aria-label="close" onClick={(e) => setShowReviewDialog(false)}>
              <CloseIcon sx={{color:'#ef757f'}} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isLoggedIn(Rails) ?
          <Formik personal-form onSubmit={onSubmitReview} initialValues={{reviewContent: ""}}>
            {children}
          </Formik>
          :
          <Grid container>
            <Grid size={12}>
              <Link to={rh.login()}>
                <span className="inNav">{I18nRails.t("Web.login")}</span>
              </Link>
              to provide review for component menu
            </Grid>
          </Grid>
        }
        {reviews &&
          <Grid container>
            {reviews.map((review, index_7) =>
              <Grid key={index_7} size={12}>
                <Card sx={{marginTop: '3%'}} elevation={4}>
                  <Grid container>
                    <Grid sx={{padding: '2%'}} size={3}>
                      <div className="avatar-img fndLocation-modal">
                        {!!review.user.user_image_url ?
                          <img src={review.user.user_image_url} onError={(e) => e.target.src=loadImage('profile_placeholder_large.png')} className="responsive-img logo-container" />
                          : null
                        }
                      </div>
                    </Grid>
                    <Grid size={8}>
                      <h4 sx={{marginBottom:0, marginTop:2}}>{review.user.first_name} {review.user.last_name}</h4>
                      <h6 sx={{marginBottom:0, marginTop:2, fontStyle:'italic', fontWeight:'300'}}>(at {dateFilter(review.created_at)})</h6>
                      <p sx={{fontSize:'14px'}}>"{review.content}"</p>
                    </Grid>
                    <Grid sx={{paddingTop:'3%'}} size={1}>
                      {isLoggedIn(Rails) &&
                        <a className="right" onClick={(e) => toggleLike(review, "Review", e)}>
                          <i className={`fas fa-heart ${user && isUserLiked(user, review, "Review") ? 'red-text' : ''}`}></i>
                          {/* | {review.favorites_count} */}
                        </a>
                      }
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
          </Grid>
        }
      </DialogContent>
    </Dialog>
  </>);
  
};

