import { multiplyCurrency, subtractCurrency } from "../utils/money";
import { applyOfferToOrderItem } from "./offer";
import { calculateTaxableItemPreTaxAmount, resetTaxableItemTotalTax } from "./tax";

export const calculateOrderItemDiscountableAmount = (orderItem) => {
  return orderItem.unit_price * orderItem.weighted_number
}

export const calculateOrderItemDiscount = (orderItem, currency) => {
  if (!!orderItem.discount_value && orderItem.discount_value > 0) {
    if (orderItem.discount_type === '%') {
      return multiplyCurrency(
        orderItem.discountable_amount || calculateOrderItemDiscountableAmount(orderItem),
        orderItem.discount_value / 100,
        currency,
      );
    } else {
      return multiplyCurrency(
        orderItem.discount_value ?? 0,
        orderItem.weighted_number,
        currency,
      );
    }        
  } 
  return orderItem.discount || 0
}

export const resetOrderItemDiscount = (orderItem, currency) => (
  orderItem.discount = calculateOrderItemDiscount(orderItem, currency)
)

export const getOrderItemDiscount = (orderItem, currency) => (
  orderItem.discount ||= calculateOrderItemDiscount(orderItem, currency)
)

export const resetOrderItemTotalDiscount = (orderItem) => (
  orderItem.discount_total = calculateOrderItemTotalDiscount(orderItem)
)

export const getOrderItemTotalDiscount = (orderItem) => (
  orderItem.discount_total ||= calculateOrderItemTotalDiscount(orderItem)
)

export const calculateOrderItemTotalDiscount = (orderItem) => {
  return (orderItem.discount || 0) + (orderItem.offer_discount || 0);
}

export const calculateOrderItemTotals = (orderItem, menuOrItemOffers, menu_discounts, currency) => {
  
  resetOrderItemDiscountableAmount(orderItem)


  resetOrderItemDiscount(orderItem, currency);
  if(!orderItem.discount || orderItem.discount <= 0) {
    applyOfferToOrderItem(orderItem, menuOrItemOffers, menu_discounts);
  }
  resetOrderItemTotalDiscount(orderItem)

  orderItem.price = subtractCurrency(
    orderItem.discountable_amount || calculateOrderItemDiscountableAmount(orderItem),
    orderItem.discount_total,
    currency,
  );
}

export const resetOrderItemDiscountableAmount = (orderItem) => {
  return orderItem.discountable_amount = calculateOrderItemDiscountableAmount(orderItem)
}

export const resetOrderItemTaxableAmount = (orderItem, currency) => {
  return orderItem.discounted_amount = resetOrderItemDiscountableAmount(orderItem) - resetOrderItemDiscount(orderItem, currency)
}

export const calculateOrderItemTaxableAmount = (orderItem, currency) => {
  return calculateOrderItemDiscountableAmount(orderItem) - calculateOrderItemDiscount(orderItem, currency)
}

export const resetOrderItemDiscountedAmount = resetOrderItemTaxableAmount
export const calculateOrderItemDiscountedAmount = calculateOrderItemTaxableAmount

export const resetOrderItemTaxableAmountWithTax = (orderItem, order, locationTaxes, isCardPayment = null) => {
  return resetOrderItemPreTaxAmount(orderItem, order, locationTaxes, isCardPayment) + 
    resetOrderItemTotalTax(orderItem, order, locationTaxes, isCardPayment)
}

export const ensureOrderItemPreTaxAmount = (orderItem, order, locationTaxes) => (
  orderItem.pre_tax_amount ||= resetOrderItemPreTaxAmount(orderItem, order, locationTaxes)
)

export const resetOrderItemPreTaxAmount = (orderItem, order, locationTaxes) => {
  orderItem.discounted_amount = resetOrderItemTaxableAmount(orderItem, order.currency);

  orderItem.pre_tax_amount = calculateTaxableItemPreTaxAmount(taxableAmount, orderItem, order, locationTaxes);
}

export const resetOrderItemTotalTax = (orderItem, order, locationTaxes, isCardPayment = null) => {
  orderItem.discounted_amount = resetOrderItemTaxableAmount(orderItem, order.currency);
  return resetTaxableItemTotalTax(orderItem, order, locationTaxes, isCardPayment);
}

// TODO need to properly calculate taxRate based on includedTax as well as additional tax
export const ensureOrderItemTaxRate = (orderItem, order, locationTaxes, isCardPayment = null) => {
  orderItem.tax_rate ||= resetOrderItemTotalTax(orderItem, order, locationTaxes, isCardPayment) / 
    resetOrderItemTaxableAmountWithTax(orderItem, order, locationTaxes, isCardPayment)
  // # menu_item.active_taxes.with_rate.average(:percentage)
}

export const getOrderItemsForPayload = (order) => {
  const order_items_attributes = order.order_items.map(orderItem => {
    if (!orderItem?.component_option_value_id && orderItem?.item_type === "MenuItem" && orderItem?.menu_item?.component_option_value_id) {
      orderItem.component_option_value_id = orderItem.menu_item.component_option_value_id;
    }
    const order_item_ingredients_attributes =
    orderItem?.order_item_ingredients_attributes?.map(ingredient => ({
        menu_item_ingredient_id: ingredient.menu_item_ingredient_id,
        number: ingredient.number,
      }));
    return {
      weighted_number: orderItem.weighted_number,
      component_option_value_id: orderItem.component_option_value_id,
      item_id: orderItem.item_id,
      item_type: orderItem.item_type,
      tax_category_id: orderItem.tax_category_id,
      offer_discount: orderItem.offer_discount,
      discount_total: orderItem.discount_total,
      discount: orderItem.discount,
      note: orderItem.note ?? '',
      order_item_ingredients_attributes,
    };
  });
  return order_items_attributes;
}

export const getOrderItemsForEditOrderPayload = (order, editOrderData, locationCart) => {
  const editOrderId = editOrderData?.id ?? null;
  const editOrderItems = editOrderData?.order_items ?? [];
  const order_items_attributes = order.order_items.map(orderItem => {
    if (!orderItem?.component_option_value_id && orderItem?.item_type === "MenuItem" && orderItem?.menu_item?.component_option_value_id) {
      orderItem.component_option_value_id = orderItem.menu_item.component_option_value_id;
    }
    const order_item_ingredients_attributes =
      orderItem?.order_item_ingredients_attributes?.map(ingredient => ({
        menu_item_ingredient_id: ingredient.menu_item_ingredient_id,
        number: ingredient.number,
      }));
  //   let itemFound = editOrderItems?.filter((cartItem) => cartItem.item_id === orderItem.item_id) ?? [];
  //   if (!!itemFound?.length > 0) {
  //     itemFound = itemFound[0];
  //     return {
  //       order_id: editOrderId,
  //       id: itemFound?.id,
  //       weighted_number: orderItem.weighted_number,
  //       component_option_value_id: orderItem.component_option_value_id,
  //       item_id: orderItem.item_id,
  //       item_type: orderItem.item_type,
  //       tax_category_id: orderItem.tax_category_id,
  //       offer_discount: orderItem.offer_discount,
  //       discount_total: orderItem.discount_total,
  //       discount: orderItem.discount,
  //       note: orderItem.note ?? '',
  //       order_item_ingredients_attributes,
  //     };
  //   } else {
  //     return {
  //       order_id: editOrderId,
  //       weighted_number: orderItem.weighted_number,
  //       component_option_value_id: orderItem.component_option_value_id,
  //       item_id: orderItem.item_id,
  //       item_type: orderItem.item_type,
  //       tax_category_id: orderItem.tax_category_id,
  //       offer_discount: orderItem.offer_discount,
  //       discount_total: orderItem.discount_total,
  //       discount: orderItem.discount,
  //       note: orderItem.note ?? '',
  //       order_item_ingredients_attributes,
  //     };
  //   }
  // });
    let itemFound = editOrderItems.find(editItem => editItem.item_id === orderItem.item_id);
    return {
      order_id: editOrderId,
      id: itemFound?.id ,
      weighted_number: orderItem.weighted_number,
      component_option_value_id: orderItem.component_option_value_id,
      item_id: orderItem.item_id,
      item_type: orderItem.item_type,
      tax_category_id: orderItem.tax_category_id,
      offer_discount: orderItem.offer_discount,
      discount_total: orderItem.discount_total,
      discount: orderItem.discount,
      note: orderItem.note ?? '',
      order_item_ingredients_attributes,
    };
  });
  editOrderItems.forEach(editItem => {
    if (!order.order_items.some(orderItem => orderItem.item_id === editItem.item_id)) {
      order_items_attributes.push({
        order_id: editOrderId,
        id: editItem.id, 
        item_id: editItem.item_id,
        item_type: editItem.item_type,
        _destroy: true, 
      });
    }
  });
  return order_items_attributes;
}

export const calculateMenuItemPrice = (
  menu_item,
  component_option_value,
  order_item_ingredients
) => {
  let option_price;
  let total_price_for_ingredients = 0;
  if (!!component_option_value?.price) {
    if (!!component_option_value.price) {
      option_price = Number(component_option_value.price);
    } else {
      option_price = 0;
    }
  } else {
    option_price = Number(menu_item.price);
  }
  //      option_price=if component_option_value then component_option_value.price else menu_item.price
  if (order_item_ingredients) {
    for (let order_item_ingredient of order_item_ingredients) {
      const { menu_item_ingredient } = order_item_ingredient;
      const orderItemIngredientNumber = order_item_ingredient.number ?? order_item_ingredient.weighted_number
      if (!!menu_item_ingredient && !!menu_item_ingredient.delta_price) {
        total_price_for_ingredients += Number(
          orderItemIngredientNumber === undefined
            ? Number(menu_item_ingredient.delta_price) +
            orderItemIngredientNumber
            : Number(menu_item_ingredient.delta_price) *
            orderItemIngredientNumber
        );
      }
    }
  }
  return total_price_for_ingredients + Number(option_price);
};

export const calculateOrderItemPrice = (orderItem) => {
  if (orderItem.item_type === 'MenuItem') {
    return calculateMenuItemPrice(
        orderItem.item || orderItem.menu_item,
        orderItem.component_option_value,
        orderItem.order_item_ingredients
      ) * orderItem.number;
  } else {
    return (orderItem.item || orderItem.menu_item).price * orderItem.number;
  }
}

export const resetOrderItemPrice = (orderItem) =>
  orderItem.price = calculateOrderItemPrice(orderItem)

export const orderItemOptionValueName = function(orderItem) {
  if (!!orderItem.component_option_value) {
    return (
      orderItem.component_option_value.name ||
      orderItem.component_option_value.option_name
    );
  }
  if (!!orderItem.option_value) {
    return orderItem.option_value.name;
  }
};

export const orderItemOptionValueDescription = function(orderItem) {
  if (!!orderItem.component_option_value) {
    return (
      orderItem.component_option_value.description ||
      orderItem.component_option_value.option_name
    );
  }
  if (!!orderItem.option_value) {
    return orderItem.option_value.description;
  }
};

export const orderItemDisplayTitle = function(orderItem) {
  if (!!orderItemOptionValueName(orderItem)) {
    return `${ orderItem.menu_item.title }, ${ orderItemOptionValueName(orderItem) }`;
  } else {
    return orderItem.menu_item.title;
  }
};

export const orderItemDisplayDescription = function(orderItem) {
  if (!!orderItemOptionValueDescription(orderItem)) {
    return `${ orderItem.menu_item.description
      }, ${ orderItemOptionValueDescription(orderItem) }`;
  } else {
    return orderItem.menu_item.description;
  }
};

export const orderItemMatchesSelection = function(
  orderItem,
  menu_item,
  component_option_value,
  order_item_ingredients
) {
  if (orderItem.item_id === menu_item.id && orderItem.item_type === 'MenuItem') {
    let component_option_value_id;
    if (!!component_option_value) {
      if (!orderItem.component_option_value_id) {
        return false;
      }
      component_option_value_id =
        component_option_value.component_option_value_id ||
        component_option_value.id;
      if (component_option_value_id !== orderItem.component_option_value_id) {
        return false;
      }
    } else {
      if (!!orderItem.component_option_value_id) {
        return false;
      } //exists in orderItem order item, but not present in the selection
    }

    if (!!order_item_ingredients) {
      if (!orderItem.order_item_ingredients) {
        return false;
      }
      if (
        order_item_ingredients.length !== orderItem.order_item_ingredients.length
      ) {
        return false;
      }
    } else {
      if (!!orderItem.order_item_ingredients) {
        return false;
      } //exists in orderItem order item, but not present in the selection
    }

    let match_found = true;
    for (let order_item_ingredient of order_item_ingredients) {
      match_found = false;
      //passed order_item_ingredients array can be a list of order_item_ingredients or menu_item_ingredients
      const menu_item_ingredient_id =
        order_item_ingredient.menu_item_ingredient_id ||
        order_item_ingredient.id;
      for (let order_item_ing of orderItem.order_item_ingredients) {
        if (
          order_item_ing.menu_item_ingredient_id === menu_item_ingredient_id
        ) {
          match_found = true;
          break;
        }
      }
      if (!match_found) {
        return false;
      }
    } //match in loop did not succeed
    return match_found; //all matches succeeded
  }

  return false;
};


export const orderItemAdjustQuantity = function(orderItem, qty) {
  orderItem.number += 1;
  resetOrderItemPrice(orderItem)
  return orderItem.number;
};

export const orderItemDisplayPrice = function(orderItem) {
  return calculateOrderItemPrice(orderItem)
};

export const buildOrderItemFromMenuItem = (
  menu_item,
  component_option_value,
  menu_item_ingredients,
  quantity,
  location,
  menu
) => {
  const order_item_ingredients = [];
  for (let selected_ingredient_key in menu_item_ingredients) {
    order_item_ingredients.push({
      menu_item_ingredient_id:
        menu_item_ingredients[selected_ingredient_key].id,
      menu_item_ingredient: menu_item_ingredients[selected_ingredient_key],
      weighted_number: menu_item_ingredients[selected_ingredient_key].count,
    });
  }

  const unit_price = calculateMenuItemPrice(
    menu_item,
    component_option_value,
    order_item_ingredients
  );
  if (!!component_option_value) {
    return {
      item_id: menu_item.id,
      item_type: 'MenuItem',
      item: menu_item,
      menu_item_id: menu_item.id,
      menu_item,
      menu,
      unit_price,
      price: unit_price * quantity,
      type: 'OrderItem',
      component_option_value_id: component_option_value.id,
      component_option_value: component_option_value, 
      weighted_number: quantity,
      order_item_ingredients,
      location_id: location.id,
    };
  } else {
    return {
      type: 'OrderItem',
      item_id: menu_item.id,
      item_type: 'MenuItem',
      item: menu_item,
      menu_item_id: menu_item.id,
      menu_item,
      menu,
      unit_price,
      price: unit_price * quantity,
      weighted_number: quantity,
      order_item_ingredients,
      location_id: location.id,
    };
  }
}

export const buildOrderItemFromOrderItem = (order_item) => {
  const {id, ...newOrderItem} = order_item;
  if (order_item.item_type === "Delivery"){
    return newOrderItem;
  }
  else {
    newOrderItem.order_item_ingredients = order_item.order_item_ingredients.map(oii => {
      const {id, order_item_id, ...newOrderItemIngredient} = oii;
      return newOrderItemIngredient;
    })
    return newOrderItem;
  }
}