import React, { useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { I18nRails } from "../../../shared/rails-i18n-js";

import _checkout_state_register from "./_checkout_state_register";
import AddressCheckoutState from "./_checkout_state_address";
// import _checkout_state_delivery_time from "./_checkout_state_delivery-time";
import _checkout_state_confirm_pin from "./_checkout_state_confirm-pin";
import _checkout_state_payment from "./_checkout_state_payment";
import _checkout_state_radios_payment from "./_checkout_state_radios_payment";

import ShowCart from "./ShowCart";

import TextField from "@mui/material/TextField";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import PlaceIcon from "@mui/icons-material/Place";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PaymentIcon from "@mui/icons-material/Payment";
import DescriptionIcon from "@mui/icons-material/Description";

import Button from "@mui/material/Button";
import { isLoggedIn } from "../helpers/utils";
import axios from "axios";
import { useSsrProps } from "../../SsrContext";

export default function CheckoutSteps({
  errorMessage,
  order_state,
  gotoCheckoutState,
  basket,
  channel,
  selectedDate,
  handleDateChange,
  order,
  requirePinCode,
  selectChannel,
  requirePhone,
  setBasketField,
  verifyVoucherCode,
  changeQuantity,
  clearCart,
  proceedToCheckout,
  user,
  showVerification,
  openGuestRegisterPasswordDialog,
  addressError,
  showCreatedUserAlert,
  handleClose,
  userInputVerification,
  addVerification,
  invalidVerificationCodeError,
  verifyOrder,
  onChangePhoneNumberEvent,
  confirmPhoneNumber,
  onChangePinEvent,
  pinCode,
  validatePinCode,
  setUserPhone,
  radioValue,
  orderProcessing,
  paypalProps,
  placeOrder,
  onRadioChange,
  selectedAddress,
  setSelectedAddress,
  cancelChannel,
  proceedAsGuest,
  setCustomer,
  setCustomerAddresses,
  customerAddresess,
  customer,
 }) {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const [guestAddress, setGuestAddress] = useState(null);
  const [guestStreet1, setGuestStreet1] = useState("");
  const [guestStreet2, setGuestStreet2] = useState("");
  const [guestCity, setGuestCity] = useState("");
  const [guestZip, setGuestZip] = useState("");
  const [showAddressError, setShowAddressError] = useState(false);
  const [showUpdatedAddressAlert, setShowUpdatedAddressAlert] = useState(false);
  const [unmappedAddress, setUnmappedAddress] = useState();
  const [thiscustomer, setThiscustomer] = useState(null);
  const [thisCutomerCredentials, setThisCutomerCredentials] = useState(null);

  const createCustomer = (values, options) => {
    if (
      values.hasOwnProperty("guestnewConfirmpassword") &&
      values.hasOwnProperty("guestnewpassword")
    ) {
      const thiscustomer = {
        ...customer,
        name: values.guestName,
        email: values.guestEmail,
        is_register: true,
        password: values.guestnewpassword,
        phone: values.guestPhone,
        whatsapp_enabled: values.guestWhatsapp ? values.guestWhatsapp : false,
        addresses_attributes: [
          {
            city: values.guestCity,
            zip: values.guestZip,
            country: "PK",
            owner_type: "Customer",
            name_door: values.guestName,
            street: values.guestStreet1,
            street2: values.guestStreet2,
          },
        ],
      };
      const thisCutomerCredentials = {
        remember_me: false,
        email: values.guestEmail,
        password: values.guestnewpassword,
        authenticity_token: "",
      };
      createCustomerAPIcall(thiscustomer, thisCutomerCredentials);
    } else if (
      values.guestRegister &&
      !values.hasOwnProperty("guestnewConfirmpassword") &&
      !values.hasOwnProperty("guestnewpassword")
    ) {
      setOpenGuestRegisterPasswordDialog(true);
      options.setSubmitting(false);
    } else if (
      !values.hasOwnProperty("guestnewConfirmpassword") &&
      !values.hasOwnProperty("guestnewpassword")
    ) {
      const thiscustomer =
        channel == "delivery"
          ? {
              ...customer,
              name: values.guestName,
              email: values.guestEmail,
              phone: values.guestPhone,
              whatsapp_enabled: values.guestWhatsapp
                ? values.guestWhatsapp
                : false,
              addresses_attributes: [
                {
                  city: values.guestCity,
                  zip: values.guestZip,
                  country: "PK",
                  owner_type: "Customer",
                  name_door: values.guestName,
                  street: values.guestStreet1,
                  street2: values.guestStreet2,
                },
              ],
            }
          : {
              ...customer,
              name: values.guestName,
              email: values.guestEmail,
              phone: values.guestPhone,
              whatsapp_enabled: values.guestWhatsapp
                ? values.guestWhatsapp
                : false,
            };
      createCustomerAPIcall(thiscustomer);
    }
  };

  const createCustomerAPIcall = (thiscustomer, thisCutomerCredentials) => {
    let promise;
    axios
      .post(`${Rails.host}/api/customers.json`, {
        location_id: me.state.basket.location_id,
        customer: thiscustomer,
      })
      .then(
        (success) => {
          if (success.statusText == "OK") {
            setShowCreatedUserAlert(true);
            const customer = success.data;
            const addresses = customer.addresses;
            //storing globally
            setCustomer(customer);
            setCustomerAddresses(addresses)
            var maxID = Math.max.apply(
              Math,
              addresses.map(function (address) {
                return address.id;
              })
            );
            var defaultAddress = addresses.find(
              (address) => address.id == maxID
            );
            //setting address
            if (channel == "delivery") {
              selectAddress(defaultAddress, true);
              setGuestStreet1(defaultAddress.street);
              setGuestStreet2(defaultAddress.street2);
              setGuestCity(defaultAddress.city);
              setGuestZip(defaultAddress.zip);
              if (thisCutomerCredentials) {
                promise = AuthFactory0.current
                  .login(thisCutomerCredentials)
                  .then(
                    (response) => {
                      if (response) {
                        if (
                          !!response.errors ||
                          (!!response.data && !!response.data.errors)
                        ) {
                          const errors =
                            response.errors ||
                            (!!response.data && !!response.data.errors);

                          setErrorMessage(errors.join("\n"));
                          // TODO: show list of errors.
                        } else {
                          const user = response.data;
                          AccessToken.current.set(user.access_token);

                          const query_params = QueryString.parse(
                            domLocation.search
                          );

                          if (!!query_params.return_url) {
                            navigate(query_params.return_url);
                          } else {
                            if (!!response.headers) {
                              if (!!response.headers.after_sign_in_path) {
                                const { after_sign_in_path } = response.headers;
                                if (
                                  after_sign_in_path !== "/manager/pos" &&
                                  after_sign_in_path !== "/"
                                ) {
                                  window.location.href =
                                    response.headers.after_sign_in_path;
                                } else {
                                  window.location.reload(false);
                                }
                              }
                              sweetalert2.fire({
                                text: "Your Account is succesfully Registered \n Logging you In. Please wait...",
                                showConfirmButton: false,
                              });
                            } else {
                              sweetalert2.fire(
                                I18nRails.t(
                                  "client.profile.login.invalid_email_or_pass"
                                )
                              );
                              options.setSubmitting(false);
                            }
                          }
                        }
                      }
                    },
                    (error) => {
                      sweetalert2.fire(
                        I18nRails.t(
                          "client.profile.login.invalid_email_or_pass"
                        )
                      );
                    }
                  );
              }
            } else {
              setSelectedAddress(defaultAddress);
            }
            setTimeout(() => {
              setGuestAddress(defaultAddress);
              setShowCreatedUserAlert(false);
              setOpenAddressDialog(false);
              setOrder_state("payment_options");
              setUser({
                ...user,
                phone: data.phone,
                email: data.email,
                name: data.name,
                id: data.id,
                token_status: "none",
                addresses: addresses,
              })
            }, 3000);
          }
          console.log("succ", success);
        },
        (error) => {
          console.log("error", error);
          setShowAddressError(true);
          setAddressError(error.response.data.errors.join("\n"))
        }
      );
  };

  const updateCustomerAddress = (e) => {
    var obj = {
      street: guestStreet1,
      street2: guestStreet2,
      city: guestCity,
      name_door: customer.name,
      country: "PK",
      zip: guestZip,
      id: customerAddresess[0].id,
      owner_id: customer.id,
      owner_type: "Customer",
    };
    var location_id = basket.location_id;
    var customer = {
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
      whatsapp_enabled: customer.whatsapp_enabled,
      addresses_attributes: [obj],
    };

    axios
      .post(`${Rails.host}/api/customers.json`, {
        location_id: location_id,
        customer,
      })
      .then(
        (success) => {
          //get address with maximum id to avoid [0] or last item picking
          var maxID = Math.max.apply(
            Math,
            success.data.addresses.map(function (address) {
              return address.id;
            })
          );
          var defaultAddress = success.data.addresses.find(
            (address) => address.id == maxID
          );
          setSelectedAddress(defaultAddress);
          setGuestAddress(defaultAddress);
          setShowUpdatedAddressAlert(true);
          setTimeout(() => {
            setEnableGuestCheckout(true);
            setOrder_state("payment");
            setShowUpdatedAddressAlert(false);
          }, 2500);
          selectAddress(defaultAddress, true);
        },
        (error) => {
          console.log("error", error);
        }
      );
  };

  const reselectAddress = () => {
    setUnmappedAddress(null);
    setSelectedAddress("");
  };

  const showAddressMap = (address) => {
    return () => {
      if (!address.lat || !address.lng) {
        address.lat = location_address(basket.location).lat;
        address.lng = location_address(basket.location).lng;
      }
      setUnmappedAddress(address);
    };
  };

  const selectAddress = (address, skipProcessCheckout) => {
    // this.setState({ openAddressDialog: false });
    //@setState address: address, address_id: address.id, deliveryResolved: false
    const { location_id } = basket;
    let params;

    if (address.id && address.id > 0) {
      params = { address_id: address.id };
    } else {
      params = { address };
    }
    Deliveries.current.query({ location_id }, params, (delivery) => {
      if (delivery.deliveries.length < 1) {
        const message = I18nRails.t(
          "venue.alertmessage.delivery_not_possible",
          {
            location_name: basket.location.name,
            contact_info: basket.location.phone,
          }
        );
        showConfirm(message, showAddressMap(address), cancelChannel);
      } else {
        if (delivery.errors.length > 0) {
          if (delivery.errors[0].pretty_message) {
            sweetalert2.fire(delivery.errors[0].pretty_message);
          } else {
            sweetalert2.fire(
              I18nRails.t("venue.alertmessage.differentaddress")
            );
          }
          //@state.selectedAddress = null
        } else {
          addDeliveryCharges(order, basket, delivery.deliveries[0]);
          setOrder(order);
          setBasket(basket);
          setDelivery(delivery);
          setDeliveryResolved(true);
          setSelectedAddress(address);
          if (!skipProcessCheckout) {
            processCheckoutState(order, order_state);
          }
        }
      }
    });
  };

  return (
    <>
      {!!errorMessage && (
        <p className="center empty-cart">
          <b>{errorMessage}</b>
        </p>
      )}
      <Accordion className="cart-state" expanded={order_state === "cart"}>
        <AccordionSummary
          onClick={(e) => gotoCheckoutState("cart")}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              <ShoppingCartIcon />
              <span style={{ marginLeft: "16px" }}>
                {I18nRails.t("client.basket.your_order")}
              </span>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className="column-flex"
          onClick={(e) => e.stopPropagation()}
        >
          <ShowCart
            hideCheckout={false}
            basket={basket}
            channel={channel}
            setBasketField={setBasketField}
            verifyVoucherCode={verifyVoucherCode}
            changeQuantity={changeQuantity}
            clearCart={clearCart}
            proceedToCheckout={proceedToCheckout}
            order={order}
          />
        </AccordionDetails>
      </Accordion>
      {!isLoggedIn(Rails) && (
        <Accordion className="cart-state" expanded={order_state === "register"}>
          <AccordionSummary
            onClick={(e) => gotoCheckoutState("register")}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <span style={{ display: "flex", alignItems: "center" }}>
                <AccountBoxIcon />
                <span style={{ marginLeft: "16px" }}>
                  {I18nRails.t("client.basket.your_order")}
                </span>
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="column-flex"
            onClick={(e) => e.stopPropagation()}
          >
            <_checkout_state_register
              showVerification={showVerification}
              openGuestRegisterPasswordDialog={openGuestRegisterPasswordDialog}
              channel={channel}
              showAddressError={showAddressError}
              addressError={addressError}
              showCreatedUserAlert={showCreatedUserAlert}
              handleClose={handleClose}
              userInputVerification={userInputVerification}
              addVerification={addVerification}
              invalidVerificationCodeError={invalidVerificationCodeError}
              verifyOrder={verifyOrder}
              createCustomer={createCustomer}
              proceedAsGuest={proceedAsGuest}
            />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        className="cart-state"
        disabled={basket.totals.numberOfCartItems < 1 ? true : false}
        expanded={order_state === "channel"}
      >
        <AccordionSummary
          onClick={(e) => gotoCheckoutState("channel")}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              <ShoppingBasketIcon />
              <span style={{ marginLeft: "16px" }}>
                {I18nRails.t("client.basket.checkout_mode")}
              </span>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className="column-flex"
          onClick={(e) => e.stopPropagation()}
        >
          {basket.items.length > 0 && (
            <>
              <p className="choose-channel">
                {I18nRails.t("Web.ordering.choose_channel")}
              </p>
              <div className="checkoutDetail">
                {basket.location &&
                  basket.location.channels &&
                  basket.location.channels.map((channel, index) => (
                    <Button
                      className="clr-dark"
                      style={{ marginRight: "1%" }}
                      key={'channel'+index}
                      onClick={(e) => selectChannel(channel.name)}
                    >
                      {channel.name}
                    </Button>
                  ))}
              </div>
            </>
          )}
          {!!selectedDate && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                ampm
                label="Want Order At"
                value={selectedDate}
                onChange={handleDateChange}
                disablePast
                format="yyyy/MM/dd HH:mm a"
                className="mui-picket-custom-width"
              />
            </LocalizationProvider>
          )}
        </AccordionDetails>
      </Accordion>
      {channel && channel === "delivery" && (
        <Accordion
          disabled={basket.totals.numberOfCartItems < 1 ? true : false}
          className={
            "cart-state" + (channel === "delivery" && user ? "" : "hide")
          }
          expanded={order_state == "address"}
        >
          <AccordionSummary
            onClick={(e) => gotoCheckoutState("address")}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <span style={{ display: "flex", alignItems: "center" }}>
                <PlaceIcon />
                <span style={{ marginLeft: "16px" }}>
                  {I18nRails.t("client.basket.delivery_address")}
                </span>
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="column-flex">
            <AddressCheckoutState
              basket={basket}
              user={user}
              selectAddress={selectAddress}
              cancelChannel={cancelChannel}
              selectedAddress={selectedAddress}
              unmappedAddress={unmappedAddress}
              showUpdatedAddressAlert={showUpdatedAddressAlert}
              guestStreet1={guestStreet1}
              guestStreet2={guestStreet2}
              guestCity={guestCity}
              guestZip={guestZip}
              setGuestStreet1={setGuestStreet1}
              setGuestStreet2={setGuestStreet2}
              setGuestCity={setGuestCity}
              setGuestZip={setGuestZip}
              updateCustomerAddress={updateCustomerAddress}
              reselectAddress={reselectAddress}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {user && user.token_status === "set" && (
        <Accordion
          disabled={basket.totals.numberOfCartItems < 1 ? true : false}
          className={
            "cart-state" + (requirePinCode() || requirePhone()) ? "" : "hide"
          }
          expanded={order_state === "confirm-pin"}
        >
          <AccordionSummary
            onClick={(e) => gotoCheckoutState("confirm-pin")}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <span style={{ display: "flex", alignItems: "center" }}>
                <VerifiedUserIcon />
                <span style={{ marginLeft: "16px" }}>
                  {I18nRails.t("confirm_phone.pin_code")}
                </span>
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="column-flex">
            <_checkout_state_confirm_pin
              requirePhone={requirePhone}
              onChangePhoneNumberEvent={onChangePhoneNumberEvent}
              phone_number={phone_number}
              confirmPhoneNumber={confirmPhoneNumber}
              requirePinCode={requirePinCode}
              onChangePinEvent={onChangePinEvent}
              pinCode={pinCode}
              validatePinCode={validatePinCode}
              setUserPhone={setUserPhone}
              basket={basket}
            />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        className="cart-state"
        disabled={basket.totals.numberOfCartItems < 1 ? true : false}
        expanded={order_state === "payment_options"}
      >
        <AccordionSummary
          onClick={(e) => gotoCheckoutState("payment_options")}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              <PaymentIcon />
              <span style={{ marginLeft: "16px" }}>
                {I18nRails.t("client.basket.payment_options")}
              </span>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="column-flex">
          <_checkout_state_radios_payment
            basket={basket}
            radioValue={radioValue}
            onRadioChange={onRadioChange}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="cart-state"
        disabled={basket.totals.numberOfCartItems < 1 ? true : false}
        expanded={order_state === "payment"}
      >
        <AccordionSummary
          onClick={(e) => gotoCheckoutState("payment")}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              <DescriptionIcon />
              <span style={{ marginLeft: "16px" }}>
                {I18nRails.t("client.basket.order_summary")}
              </span>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="column-flex">
          <_checkout_state_payment
            basket={basket}
            channel={channel}
            setBasketField={setBasketField}
            verifyVoucherCode={verifyVoucherCode}
            changeQuantity={changeQuantity}
            clearCart={clearCart}
            proceedToCheckout={proceedToCheckout}
            order={order}
            radioValue={radioValue}
            orderProcessing={orderProcessing}
            paypalProps={paypalProps}
            placeOrder={placeOrder}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
