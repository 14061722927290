import React from 'react';
import { I18nRails } from '../../../shared/rails-i18n-js';
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import { useSsrProps } from '../../SsrContext';

const template = ({
  requirePhone,
  onChangePhoneNumberEvent,
  phone_number,
  confirmPhoneNumber,
  requirePinCode,
  onChangePinEvent,
  pin_code,
  validatePinCode,
  setUserPhone,
  basket
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  return (
    (<form>
      {requirePhone() && isLoggedIn(Rails) && (
        <div className="token-box">
          <div className="confirm">
            {I18nRails.t('client.profile.phone.we_need_your_phone_number_order')}
          </div>
          <Grid container>
            <Grid size={3}>
              <TextField
                label="Phone Number"
                onChange={onChangePhoneNumberEvent}
                value={phone_number}
                type="tel"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid size={12}>
              <Button
                className="btn-second"
                style={{ marginTop: '2%' }}
                onClick={(e) =>
                  confirmPhoneNumber(
                    basket,
                    phone_number
                  )
                }
              >
                {I18nRails.t('client.profile.phone.confirm')}
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      {requirePinCode() && (
        <Grid container>
          <Grid size={3}>
            <TextField
              inputProps={{ maxLength: 4 }}
              type="password"
              onChange={onChangePinEvent}
              value={pin_code}
              label="Pin Code"
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid size={12}>
            <Button
              className="left mix-btns btn-second"
              style={{ marginTop: '2%' }}
              onClick={(e) => validatePinCode()}
            >
              {I18nRails.t('client.profile.phone.confirm_pin')}
            </Button>
            <Button
              className="left mix-btns btn-second"
              style={{ marginLeft: '2%', marginTop: '2%' }}
              onClick={(e) => setUserPhone('')}
            >
              {I18nRails.t('client.basket.send_me_a_pin')}
            </Button>
          </Grid>
        </Grid>
      )}
    </form>)
  );
  
};

export default template;
