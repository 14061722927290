import React, { useState } from 'react';
import { Link } from 'react-router';
import { I18nRails } from '../../shared/rails-i18n-js';
import * as rh from './helpers/routes';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { orderItemDisplayTitle } from '../models/order_item';
import { resetBasketTotals } from './Model/basket';
import CartService from '../services/CartService';
import { useSsrProps } from '../SsrContext';


const ShowIndexCart = ({
  clearCart,
  basket,
  increaseQuantity,
  decreaseQuantity
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  resetBasketTotals(basket, CartService.get(Rails));

  return <>
    {basket.totals.numberOfCartItems < 1 && (

      <p className="center empty-cart">
        <b>{I18nRails.t('client.basket.your_basket_is_empty')}</b>
        <br />
        <b>
          <Link
            style={{ color: 'red' }}
            to={rh.show_location({ location_id: basket.location_id })}
          >
            {I18nRails.t('client.basket.go_back_menus')}
          </Link>
        </b>
      </p>
    )}
    <Grid container>
      <Grid size={12}>
        {basket.totals.numberOfCartItems > 0 && (
          <Table className="striped">
            <TableHead>
              <TableRow>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.item')}
                </TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.price')}
                </TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.add')}
                </TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.quantity')}
                </TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.remove')}
                </TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('client.basket.total_price')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {basket.totals.numberOfCartItems > 0 &&
                basket.items.map((order_item, index) => {
                  return (
                    <TableRow key={'hom-oi' + index}>
                      {order_item.type === 'OrderItem' ? (
                        <>
                          <TableCell>{orderItemDisplayTitle(order_item)}</TableCell>
                          <TableCell>
                            {order_item.unit_price || order_item.menu_item.price}
                          </TableCell>
                          <TableCell>
                            <a
                              className="btn-order"
                              onClick={(e) =>
                                increaseQuantity(
                                  order_item,
                                  basket,
                                  e
                                )
                              }
                            >
                              <i className="green-text fas fa-plus-circle"></i>
                            </a>
                          </TableCell>
                          <TableCell>{order_item.weighted_number}</TableCell>
                          <TableCell>
                            <a
                              className="btn-order"
                              onClick={(e) =>
                                decreaseQuantity(
                                  order_item,
                                  basket,
                                  e
                                )
                              }
                            >
                              <i className="red-text fas fa-minus-circle"></i>
                            </a>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell>&nbsp;</TableCell>
                        </>
                      )}
                      <TableCell>{order_item.price}</TableCell>
                    </TableRow>
                  );
                })}
              {basket.totals.numberOfCartItems > 0 && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{I18nRails.t('Web.total')}</TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.total, {
                      unit: basket.location.currency_symbol
                    })}
                  </TableCell>
                </TableRow>
              )}
              {basket.totals.voucher_discount > 0 && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{I18nRails.t('Web.voucher_discount')}</TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.voucher_discount, { unit: basket.location.currency_symbol })}
                  </TableCell>
                </TableRow>
              )}
              {basket.totals.discount > 0 && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{I18nRails.t('Web.discount')}</TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.discount, { unit: basket.location.currency_symbol })}
                  </TableCell>
                </TableRow>
              )}
              {basket.totals.gst > 0 && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{I18nRails.t('Web.tax')}</TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.gst, { unit: basket.location.currency_symbol })}
                  </TableCell>
                </TableRow>
              )}
              {basket.totals.delivery_total > 0 && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>
                    <i className="material-icons center subHeadIcons second-color motorcycle"></i>
                    <span>{I18nRails.t('Web.delivery_charges')}</span>
                  </TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.delivery_charges, { unit: basket.location.currency_symbol })}
                  </TableCell>
                </TableRow>
              )}
              {(!basket.totals.delivery_total || !basket.totals.gst || !!basket.totals.discount) && (
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{I18nRails.t('Web.grand_total')}</TableCell>
                  <TableCell>
                    {I18nRails.numberToCurrency(basket.totals.final_price, { unit: basket.location.currency_symbol })}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </Grid>
      <br/>
      <Grid container style={{paddingLeft:'3%',paddingRight:'3%'}}>
        <Grid style={{textAlign: 'left'}} size={6}>
          {basket.totals.numberOfCartItems > 0 &&
            <Button className="btn-red" style={{ cursor: 'pointer', paddingTop: '2%', paddingBottom: '2%' }} onClick={(e) => clearCart(basket)}>
              {I18nRails.t('client.basket.empty_basket')}
            </Button>
          }
        </Grid>
        <Grid style={{textAlign: 'right', alignContent: 'flex-end'}} size={6}>
          {basket.totals.numberOfCartItems > 0 &&
            <Button className="clr-dark" component={Link} to={rh.location_cart({ location_id: basket.location_id })} style={{ cursor: 'pointer', paddingTop: '2%', paddingBottom: '2%' }}>
              {I18nRails.t('client.basket.checkout')}
            </Button>
          }
        </Grid>
      </Grid>
    </Grid>
  </>;
};

export default ShowIndexCart;
