import React, { useEffect } from 'react';


import AppLayout from './AppLayout';
import InfiniteOrders from './InfiniteOrders';

import { I18nRails } from '../../shared/rails-i18n-js';

import * as rh from './helpers/routes';

import _loginOrSignup from './templates/_loginOrSignup';
import withOrders from './hoc/withOrders';
import withParent from './hoc/withParent';
import { useLocation } from 'react-router';
import { useSsrProps } from '../SsrContext';

const AppOrders = ({
  listenForUpdates,
  locationId,
  loadingOrders,
  user,
  hasMore,
  infiniteOrders,
  loadMoreOrders,
  ...other
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;


  const domLocation = useLocation();
  useEffect(() => {
      listenForUpdates(locationId);
  }, [locationId]);

  return (
    <AppLayout user={user} {...other}>
      {user ? (
        <InfiniteOrders
          items={infiniteOrders.items}
          loadMoreOrders={loadMoreOrders}
          loading={loadingOrders}
          hasMore={hasMore}
          {...other}
        />
      ) : (
        _loginOrSignup('Login to your existing account or Sign Up.', domLocation)
      )}
    </AppLayout>
  );
};


export default withParent(withOrders(AppOrders));