import FoodnerdResource from './FoodnerdResource';
import { getLocationDistance, getLocationTime, initLocationOpeningHours, isLocationOnlineOrdersActive, isLocationOpenNow, localizedLocationWeeklySchedules, locationHasDelivery, locationHasOffers, locationOperationHours, locationTimeToday, location_address, location_card_logo_url, location_check_range, location_check_same_charges, location_future_orders_activated, location_header, location_img_email_url, location_img_thumb_url, location_logo_url, location_sidebar_logo_url, location_small_logo_url } from '../models/location';

export default class LocationResource {
  static get(Rails) {
    const mappings = { id: ":id" };
    const baseUrl = `${ Rails.host }/api/locations`;
    const Location = new FoodnerdResource(Rails.access_token, `${ baseUrl }/{:id}.json`, mappings, {
      // for locations ?
      search: {
        url: `${ baseUrl }/search.json`,
        // cache: ApplicationCacheFactory.get()
        method: "GET",
      },
      time_status: {
        url: `${ baseUrl }/{:id}/time_status`,
        // cache: ApplicationCacheFactory.get()
        method: "GET",
      },
      query: {
        url: `${ baseUrl }.json`,
        // cache: ApplicationCacheFactory.get()
        method: "GET",
      },
      get: {
        method: "GET",
      },
    });

    this.attachMethods(Location.prototype, Rails);
    return Location;
  }
  static attachMethods(locationObj, Rails) {
    locationObj.friends_likes = function(user) {
      return getUserFriendsLikesForLocation(user, this);
    };
    locationObj.like = function(user) {
      return setUserLike(user, this, "Location");
    };
    locationObj.unlike = function(user) {
      return setUserUnLike(user, this, "Location");
    };
    locationObj.is_liked_by = function(user) {
      return isUserLiked(user, this, "Location");
    };

    locationObj.logo_url = function() {
      return location_logo_url(this, Rails)
    };

    locationObj.img_thumb_url = function() {
      return location_img_thumb_url(this)
    };

    locationObj.img_email_url = function() {
      return location_img_email_url(this)
    };

    locationObj.small_logo_url = function() {
      return location_small_logo_url(this, Rails)
    };

    locationObj.card_logo_url = function() {
      return location_card_logo_url(this, Rails)
    };

    locationObj.sidebar_logo_url = function() {
      return location_sidebar_logo_url(this, Rails)
    };

    locationObj.main_image_url = function() {
      return location_main_image_url(this, Rails)
    };

    locationObj.header = function() {
      return location_header(this)
    };

    locationObj.future_orders_activated = function() {
      return location_future_orders_activated(this)
    };

    locationObj.address = function() {
      return location_address(this)
    };

    locationObj.getDistance = function(coords) {
      return getLocationDistance(this, coords);
    };

    locationObj.isOnlineOrdersActive = function() {
      return isLocationOnlineOrdersActive(this)
    };

    locationObj.check_same_charges = function() {
      return location_check_same_charges(this)
    };

    locationObj.check_range = function() {
      return location_check_range(this)
    };

    locationObj.hasOffers = function() {
      return locationHasOffers(this)
    };

    locationObj.hasDelivery = function() {
      return locationHasDelivery(this)
    };

    locationObj.localizedWeeklySchedules = function() {
      return localizedLocationWeeklySchedules(this)
    };

    locationObj.operationHours = function() {
      return locationOperationHours(this)
    };

    locationObj.initOpeningHours = function() {
      return initLocationOpeningHours(this)
    };

    locationObj.isOpenNow = function() {
      return isLocationOpenNow(this)
    };

    locationObj.timeToday = function() {
      return locationTimeToday(this)
    };

    locationObj.getTime = function() {
      return getLocationTime(this)
    };
    return locationObj;
  }
}
