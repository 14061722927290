import React, { useRef } from "react";
import LocationCard from "./LocationCard";
import LocationResource from "../resources/location";
import Grid from "@mui/material/Grid";
import withParent from "./hoc/withParent";
import { useSsrProps } from "../SsrContext";


const FavoriteLocations = props => {
  const {
    favorites
  } = props;

  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const Location = LocationResource.get(Rails);
  var favs = [];
  favorites.forEach((fav) => {
    var fdlocation = new Location(fav.obj);
    fdlocation.red = true;
    favs.push(fdlocation);
  });
  return (
    (<Grid container spacing={4} style={{marginTop: '5%', paddingBottom: '5%', paddingLeft: '3%', paddingRight: '3%', width: '100%'}}>
      {favs.map(fav => (
        <Grid size={4}>
          <LocationCard
            id={fav.id}
            inFavoriteRoute={true}
            isLiked={props.isLiked}
            fndLocation={fav}
            toggleFavoriteLike={props.toggleFavoriteLike}
            latLng={props.latLng}
          />
        </Grid>
      ))}
    </Grid>)
  );
};

export default withParent(FavoriteLocations);

