import React, { useState } from "react";

import { Link, useLocation, useParams } from "react-router";

const withReview =
  (Component) =>
  ({
    resource_id_column_name,
    Review,
    locationId,
    createReview,
    fdReviews,
    ...other
  }) => {

    const params = useParams();
    const domLocation = useLocation();
    const [review_title, setReview_title] = useState("Menu Item Reviews");
    const [reviews, setReviews] = useState([]);
    const [resource_id, setResource_id] = useState(params.location_id);
    const [editingFlag, setEditingFlag] = useState(false);
    const [editingReview, setEditingReview] = useState("");
    const [contentEdited, setContentEdited] = useState("");
    const [review, setReview] = useState();
    const [editedReviewContent, setEditedReviewContent] = useState();

    const setReviewContent = (e) => {
      setReview({ ...review, content: e.target.value });
    };

    function enableEditing(review, e) {
      setEditingFlag(true);
      setEditingReview(review);
      setEditedReviewContent(review.content);
    }

    function cancelEditingReview() {
      setEditingFlag(false);
    }

    // useEffect(() => {
    //   if (!fdReviews) {
    //     const Review = LocationReview.get(Rails)
    //     resource_id_column_name ||= "location_id"
    //     Review.query(
    //       { [resource_id_column_name]: resource_id },
    //       (reviews) => {
    //         setReviews(reviews);
    //       }
    //     );
    //   } else {
    //     const reviews = [];
    //     JSON.parse(fdReviews).forEach((review) => {
    //       reviews.push(new Review(review));
    //     });
    //     setReviews(reviews);
    //   }
    // }, []);

    return (
      <Component
        resource_id_column_name={resource_id_column_name}
        Review={Review}
        locationId={locationId}
        createReview={createReview}
        fdReviews={fdReviews}
        review_title
        setReview_title={setReview_title}
        reviews={reviews}
        setReviews={setReviews}
        resource_id={resource_id}
        setResource_id={setResource_id}
        editingFlag={editingFlag}
        setEditingFlag={setEditingFlag}
        editingReview={editingReview}
        setEditingReview={setEditingReview}
        contentEdited={contentEdited}
        setContentEdited={setContentEdited}
        review={review}
        setReview={setReview}
        editedReviewContent={editedReviewContent}
        setEditedReviewContent={setEditedReviewContent}
        setReviewContent={setReviewContent}
        enableEditing={enableEditing}
        cancelEditingReview={cancelEditingReview}
        {...other}
      />
    );
  };

export default withReview;
