import React, { useState, useRef, useEffect } from "react";

import { I18nRails } from "../../../shared/rails-i18n-js";

import sweetalert2 from "sweetalert2";
import CartService from "../../services/CartService";
import VoucherResource from "../../resources/voucher";
import LocationResource from "../../resources/location";
import UserPhoneResource from "../../resources/user-phone";
import { resetBasketTotals } from "../Model/basket";
import { isLoggedIn } from "../helpers/utils";
import { useSsrProps } from "../../SsrContext";

const withCart =
  (Component) =>
  ({
    loadUser,
    user,
    setUser,
    proceedToPageCheckout,
    showResourceError,
    order,
    setOrder,
    ...other
  }) => {
    const ssrProps = useSsrProps()
    const {Rails} = ssrProps;

    const [baskets, setBaskets] = useState([]);
    const [totals, setTotals] = useState({});
    const [checkoutStarted, setCheckoutStarted] = useState(false);
    const [basket, setBasket] = useState("");

    const verifyVoucherCode = (voucher_code, basket, channel, e) => {
      if (!!e) {
        e.preventDefault();
      }
      if (!basket.totals) {
        basket.totals = {};
      }
      setTotals(basket.totals)

      if (!!voucher_code && !!basket.location_id) {
        const Voucher = VoucherResource.get(Rails);
        Voucher.verify(
          {},
          { code: voucher_code, location_id: basket.location_id, channel },
          (voucher) => {
            if (!!voucher.errors) {
              sweetalert2.fire(voucher.errors);
              console.log(`${voucher.errors}`);
              return;
            }
            applyVoucher(voucher, basket);
            const cartService = CartService.get(Rails, localStorage);
            resetBasketTotals(basket, cartService);
            setTotals(basket.totals);
            setBasket(basket);
          },
          (error) => {
            sweetalert2.fire(
              `${I18nRails.t("Web.invalid_voucher")}: ${voucher_code}`
            );
          }
        );
      } else {
        sweetalert2.fire(I18nRails.t("Web.empty_voucher"));
      }
    };

    const applyVoucher = (voucher, basket) => {
      let amount;
      const cartService = CartService.get(Rails, localStorage);
      const total = cartService.getTotal(basket.location_id);
      cartService.setVoucher(voucher, basket);

      if (!basket.totals) {
        basket.totals = {};
      }

      basket.totals.voucher_discount = 0;

      switch (voucher.discount_type) {
        case "percentage":
          if (voucher.min_order_value <= total) {
            basket.totals.voucher_discount =
              (parseFloat(voucher.discount) / 100) * total;
            if (
              !!voucher.max_discount &&
              basket.totals.voucher_discount > voucher.max_discount
            ) {
              basket.totals.voucher_discount = voucher.max_discount;
            }
          } else {
            amount = I18nRails.numberToCurrency(voucher.min_order_value, {
              unit: basket.location.currency_symbol,
              stripInsignificantZeros: true,
            });
            sweetalert2.fire(
              `Minimum order should be ${amount} to get discount`
            );
          }
          break;
        case "fixed":
          if (voucher.min_order_value <= total) {
            basket.totals.voucher_discount = parseFloat(voucher.discount);
          } else {
            amount = I18nRails.numberToCurrency(voucher.min_order_value, {
              unit: basket.location.currency_symbol,
              stripInsignificantZeros: true,
            });
            sweetalert2.fire(
              `Minimum order should be ${amount} to get discount`
            );
          }
          break;
      }
      return basket.totals.voucher_discount;
    };

    function increaseQuantity(order_item, basket, e) {
      const cartService = CartService.get(Rails, localStorage);
      cartService.increaseQuantity(basket.location_id, order_item);
      const updated_basket = cartService.get(basket.location_id);
      if (!!basket.voucher_code) {
        verifyVoucherCode(basket.voucher_code, updated_basket, e);
      }
      resetBasketTotals(updated_basket, cartService);
      setTotals(updated_basket.totals);
      setBasket(updated_basket);
    }

    function decreaseQuantity(order_item, basket, e) {
      const cartService = CartService.get(Rails, localStorage);
      cartService.decreaseQuantity(basket.location_id, order_item);
      const updated_basket = cartService.get(basket.location_id);
      if (!!basket.voucher_code) {
        verifyVoucherCode(basket.voucher_code, updated_basket, e);
      }
      resetBasketTotals(updated_basket, cartService);
      setTotals(updated_basket.totals);
      setBasket(updated_basket);
    }

    const setUserPhone = (phone) => {
      setUser({ ...user, phone });
    };

    function confirmPhoneNumber(basket, phone, do_redirect) {
      const UserPhone = UserPhoneResource.get(Rails);
      UserPhone.create(
        { user: { phone } },
        (response) => {
          setUserPhone(phone);
          if (!!Rails.require_phone_verification) {
            const success = I18nRails.t("confirm_phone.alert_message");
            sweetalert2.fire(`${success}`);
            if (!!do_redirect) {
              window.location.hash = "checkout";
            }
          } else {
            if (!!do_redirect) {
              window.location.hash = "checkout";
            } else {
              processCheckoutState(order);
            }
          }
        },
        (error) => {
          showResourceError.current(error);
        }
      );
    }

    useEffect(() => {
      if (!!basket) {
        setBaskets([basket]);
        setTotals(basket.totals);
      } else {
        const cartService = CartService.get(Rails, localStorage);
        const allBaskets = cartService.getAll();
        const basketsWithLocationId = [];
        allBaskets.forEach(basket => {
          if (!!basket.location_id) {
            basketsWithLocationId.push(basket);
          }
          resetBasketTotals(basket, cartService);
          setTotals(basket.totals);
        });
        setBaskets(basketsWithLocationId);
      }
    }, []);

    return (
      <Component
        baskets={baskets}
        setBaskets={setBaskets}
        totals={totals}
        setTotals={setTotals}
        checkoutStarted={checkoutStarted}
        setCheckoutStarted={setCheckoutStarted}
        basket={basket}
        setBasket={setBasket}
        order={order}
        isLoggedIn={isLoggedIn}
        loadUser={loadUser}
        showResourceError={showResourceError}
        user={user}
        setUser={setUser}
        proceedToPageCheckout={proceedToPageCheckout}
        setOrder={setOrder}
        verifyVoucherCode={verifyVoucherCode}
        applyVoucher={applyVoucher}
        increaseQuantity={increaseQuantity}
        decreaseQuantity={decreaseQuantity}
        setUserPhone={setUserPhone}
        confirmPhoneNumber={confirmPhoneNumber}
        {...other}
      />
    );
  };

export default withCart;
